import React from 'react';
import {
  addLineBreake,
  replaceFormating,
  replaceVariables,
} from '../../../modules/inbox/helper';
import ImageSvg from '../../../assets/customSVG/ImageSvg';
import { ICONS } from '../../../assets/icons';

export default function CarouselCardPreview({ data, useTemplates, carousel }) {
  const btnStyle1 = `flex items-center gap-[0.5vw] h-[2vw] text-[#007AFF] text-[0.8vw] font-[500]`;

  return (
    <div className='w-full h-full p-[0.5vw] pb-0 bg-white rounded-[0.4vw]'>
      <div className='w-full h-[15vh] rounded-[0.4vw] border border-[var(--border-50)] flex items-center justify-center overflow-hidden'>
        {data?.image ? (
          <>
            {carousel?.header === 'IMAGE' && (
              <img
                src={data?.image}
                alt='carousel product image'
                className='w-full h-full object-cover rounded-[0.4vw]'
              />
            )}
            {carousel?.header === 'VIDEO' && (
              <video
                className='w-full h-full object-cover rounded-[0.4vw]'
                src={data?.image}
                autoPlay
              />
            )}
          </>
        ) : (
          <div className='w-full h-[8rem] flex items-center justify-center'>
            <ImageSvg color={'var(--BG-200)'} width={40} height={40} />
          </div>
        )}
      </div>
      {data?.bodyText && (
        <p
          className='font-sm weight-small px-[0.5vw] pt-[1vw] pb-[0.5vw] break-words bg-white border-b border-[var(--border-50)]'
          dangerouslySetInnerHTML={{
            __html: useTemplates
              ? replaceVariables(
                  addLineBreake(replaceFormating(data?.bodyText)),
                  data?.bodyVariables
                )
              : addLineBreake(replaceFormating(data?.bodyText)),
          }}
        >
          {/* {body?.text} */}
        </p>
      )}
      <div className=''>
        {data?.buttons?.map((b) => {
          return (
            <div className='font-sm weight-medium w-fill flex items-center justify-center border-b border-[var(--border-50)] last:border-none'>
              {b?.type === 'URL' && (
                <button className={`${btnStyle1}`}>
                  <img
                    src={ICONS?.nounLink}
                    alt='urlIcon'
                    className='size-[0.9vw]'
                  />
                  {b?.text}
                </button>
              )}
              {b?.type === 'PHONE_NUMBER' && (
                <button className={`${btnStyle1}`}>
                  <img
                    src={ICONS?.call}
                    alt='phoneNumberIcon'
                    className='size-[0.95vw]'
                  />
                  {b?.text}
                </button>
              )}

              {b?.type === 'COPY_CODE' && (
                <button className={`${btnStyle1}`}>
                  <img
                    src={ICONS?.CopyLeft}
                    alt='copeCodeIcon'
                    className='size-[0.95vw]'
                  />
                  <span>Copy Offer Code</span>
                </button>
              )}

              {b?.type === 'QUICK_REPLY' && (
                <div
                  key={`custom-button`}
                  // className={`flex items-center gap-[0.5vw] h-[2.5vw]`}
                  className={`${btnStyle1}`}
                >
                  <img
                    src={ICONS?.shareLink}
                    alt='shareBtn'
                    className='size-[0.8vw]'
                  />
                  <p className=''>{b?.text}</p>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
