import React, { useEffect, useState } from "react";
import "../../styles/Campaign.css";

import { images } from "../../../../assets/images";
import { ICONS } from "../../../../assets/icons";

import { Button } from "../../../../components/form/Button/Button";
import CampaignModal from "../../../../components/commonComponents/campaign-popup/CampaignModal";
import { Modal } from "antd";
import { useLocation } from "react-router-dom";

const NoData = ({}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleCancel = () => setIsModalVisible(false);
  const currentPath = window.location.pathname;
  const currentPathPerpetual = "/user/campaign/perpetual";

  return (
    <div className="no-data-container flex-column align-center">
      {isModalVisible && <div className="modal-overlay" />}

      <div className="no-data-wrapper  flex-column align-center justify-center">
        <img src={images?.noCampaign} alt="noCampaign" />
        <span>No Campaign</span>
        <p>
          No campaigns have been found. Click the button below to create one.
        </p>
        <div className="campaign-button pointer">
          {!currentPath?.startsWith(currentPathPerpetual) ? (
            <Button
              label={"Create Campaign"}
              size={"medium"}
              iconPrefix={ICONS.speakerphoneWhite}
              onClick={() => setIsModalVisible(true)}
            />
          ) : (
            currentPath?.startsWith(currentPathPerpetual) && (
              <button
                style={{
                  cursor: "not-allowed",
                  backgroundColor: "var(--primary)",
                  color: "white",
                  fontSize: "0.9vw",
                  fontWeight: "600",
                  padding: "6px 12px",
                  borderRadius: "6px",
                }}
              >
                <span className="flex-row align-center">
                  <img
                    src={ICONS.speakerphoneWhite}
                    alt="speakerphoneWhite"
                    className="w-5"
                  />
                  Create Campaign
                </span>
              </button>
            )
          )}
          {/* )} */}
          <Modal
            open={isModalVisible}
            footer={null}
            className={"campaign-modal"}
            closable={false}
            centered={true}
            // onCancel={handleCancel}
            width="40%"
          >
            {
              <CampaignModal
                handleCancel={handleCancel}
                setIsModalVisible={setIsModalVisible}
              />
            }
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default NoData;
