import React from 'react';
import { cn } from '../../../../helper/cn';

function NewAlert({
  handleCancel,
  handleYes,
  handleNo,
  content,
  yesName,
  noName,
  loading,
  icon,
  title,
  disabled,
  type = 'old',
  buttonColor = null,
}) {
  return (
    <div
      className={cn(
        'p-[30px] alertInfoWrapper',
        type === 'new' ? 'rounded-2xl' : ''
      )}
    >
      <div className='header w-100 flex-row  flex-start'>
        {icon && (
          <img
            className='alert-icons pointer'
            src={icon}
            alt='popup'
            onClick={handleCancel}
          />
        )}
      </div>
      <div className='flex-column mt-20'>
        {/* <img className="alert pointer" src={ICONS.AlertIcon} alt="popup" /> */}
        {title && <div className='mt-10 title'>{title}</div>}
        <div className='mt-10 content'>{content}</div>
        <div className='flex-row flex-end align-center mt-10  gap-5'>
          <div
            className='mr-[15px] noButton cursor-pointer hover:bg-[var(--channelButton)] p-10 b-radius-6'
            onClick={() => handleNo()}
          >
            {yesName}
          </div>
          {/* <Button
            label={noName}
            size={"medium"}
            onClick={() => handleYes()}
            loading={loading}
            disabled={disabled}
            style={{
              ...buttonStyle, // Apply custom button styles here
              cursor: disabled ? "not-allowed" : "pointer",
              backgroundColor: disabled
                ? "var(--lightGrey)"
                : "#F96056 !important",
              color: disabled
                ? "var(--darkgrey)"
                : buttonStyle?.color || "white",
            }}
            data-testid="custom-button"
          /> */}
          <button
            onClick={() => handleYes()}
            loading={loading}
            disabled={disabled}
            style={{
              cursor: disabled ? 'not-allowed' : 'pointer',
              backgroundColor: disabled
                ? 'var(--lightGrey)'
                : buttonColor
                ? buttonColor
                : '#F96056',
              color: disabled ? 'var(--darkgrey)' : 'white',
              fontSize: '0.9vw',
              fontWeight: '600',
              padding: '10px 15px',
              borderRadius: '6px',
            }}
            data-testid='custom-button'
          >
            {noName}
          </button>
        </div>
      </div>
    </div>
  );
}

export default NewAlert;
