import React, { useEffect, useReducer, useRef, useState } from "react";

// API
import { templateLists } from "../../../modules/channels/api/Api";

// assets
import { ICONS } from "assets/icons";
import VideoSvg from "assets/customSVG/VideoSvg";

// helper
import { getLanguageName } from "helper/setLanguageCode";
import { capitalizeFirstLetter } from "helper/capitalizeFirstLetter";

// utils
import InfiniteScroll from "react-infinite-scroll-component";

// redux
import { useAspDispatch, useAspSelector } from "../../../test/jest-redux-hooks";

// hooks
import useToggle from "hooks/useToggle";
import { useSearch } from "hooks/useSearch";
import { useFileUploader } from "hooks/useFileUploader";
import { useTemplateVariables } from "hooks/templates/useTemplateVariables";

// reducers
import {
  INITIAL_STATE,
  templateReducer,
} from "hooks/templates/templateReducers";

// components
import Loader from "../Loader/Index";
import TemplateCard from "./TemplateCard";
import SearchBar from "../searchBar";
import TemplatePreview from "./TemplatePreview";
import VariablesPreviewCard from "./VariablesPreviewCard";
import NoData from "components/commonComponents/Nodata/index";

let limit = 10;
let count = 0;
let page = 1;

export default function TemplateListContainer({
  isSending,
  className,
  sendTemplate,
  changeMedia = true,
  changeVariable = true,
}) {
  const { currentBrand } = useAspSelector((state) => state?.app);
  const dispatch = useAspDispatch();

  const { uploading, selectedFile, setSelectedFile, uploadFiles, error } =
    useFileUploader();

  const [loading, setLoading] = useToggle(true);
  const [isLoadingNextPage, setIsLoadingNextPage] = useToggle(false);
  const { searchKey, setSearchKey, onChange: onSearch } = useSearch("");
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templates, setTemplates] = useState([]);

  const templateListContainerRef = useRef(null);

  // selected template
  const [useTemplates, setUseTemplates] = useToggle(false);

  const { variables, updateVariables, getVariablesFromStr } =
    useTemplateVariables();
  const [templateData, templateDispatch] = useReducer(
    templateReducer,
    INITIAL_STATE
  );
  const {
    header,
    headerVariable,
    body,
    bodyVariables,
    footer,
    buttons,
    buttonsOrder,
    ctaButtons,
    customButtons,
    carousel,
    authentication,
  } = templateData;

  useEffect(() => {
    page = 1;
    if (limit > 0)
      fetchTemplateLists(true, currentBrand?.brand_id, {
        page: page,
        limit: limit,
        name: searchKey,
        status: "ACTIVE",
        category: [2, 3],
      });
  }, [currentBrand]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      page = 1;
      if (limit > 0)
        fetchTemplateLists(true, currentBrand?.brand_id, {
          page: page,
          limit: limit,
          name: searchKey,
          status: "ACTIVE",
          category: [2, 3],
        });
    }, 500);

    return () => clearTimeout(timeOut);
  }, [searchKey]);

  useEffect(() => {
    templateDispatch({
      type: "update_template",
      data: selectedTemplate,
    });
  }, [selectedTemplate]);

  const fetchTemplateLists = async (load, brandId, payload) => {
    if (brandId) {
      if (load) setLoading(true);
      if (!load) setIsLoadingNextPage(true);

      await templateLists(brandId, payload)
        .then((res) => {
          if (res && res.data?.templates?.length > 0) {
            if (page === 1) setTemplates(res?.data?.templates);
            else
              setTemplates([
                ...new Set([...templates, ...res?.data?.templates]),
              ]);
            count = res?.data?.template_count;
          } else {
            setTemplates([]);
          }
        })
        .finally(() => {
          if (load) setLoading(false);
          if (!load) setIsLoadingNextPage(false);
        });
    } else {
      setTemplates([]);
    }
  };

  const loadNextPage = () => {
    page += 1;
    fetchTemplateLists(false, currentBrand?.brand_id, {
      page: page,
      limit: limit,
      name: searchKey,
      status: "ACTIVE",
      category: [2, 3],
    });
  };

  const updateTemplate = (type, key, value) => {
    if (selectedTemplate)
      if (["HEADER", "BODY", "FOOTER", "BUTTONS"].includes(type)) {
        const filterType = selectedTemplate?.components?.filter(
          (c) => c.type === type
        );

        const filterWithoutType = selectedTemplate?.components?.filter(
          (c) => c.type !== type
        );

        const updatedData = {
          ...selectedTemplate,
          components: [
            ...filterWithoutType,
            {
              ...filterType?.[0],
              [key]: value,
            },
          ],
        };
        setSelectedTemplate(updatedData);
      } else {
        setSelectedTemplate({ ...selectedTemplate, [key]: value });
      }
  };

  // get the file name from media file
  const fileName = (url) => {
    const names = url?.split("/");
    return names?.[names.length - 1];
  };

  // styles
  const uploadContainer =
    "flex items-center gap-[0.3vw] bg-[var(--primary)] px-[0.5vw] py-[0.1.5vw] cursor-pointer";
  const errorStyle = "text-[var(--fontRed)] font-md weight-400 my-[0.5vw]";

  return (
    <div className="w-full h-full min-h-[70vh] max-h-[73vh] p-[1vw]">
      <div className="flex items-center justify-between mb-[1vw]">
        {useTemplates ? (
          <div className="flex items-center gap-[0.6vw]">
            <button
              className="cursor-pointer"
              onClick={() => {
                setUseTemplates(false);
                setSelectedFile(null);
              }}
            >
              <img
                src={ICONS?.BackIcon}
                alt="back icons"
                className="size-[1.5vw]"
              />
            </button>
            <p className="font-larger weight-bold">{selectedTemplate?.name}</p>
            <p
              className={`px-[0.6vw] py-[0.2vw] font-xs weight-semibold rounded-[0.25vw]
              ${
                selectedTemplate?.category === "MARKETING"
                  ? "bg-[var(--statusBg)] text-[var(--statusFont)]"
                  : "bg-[#FFFBEB] text-[#F6A723]"
              }`}
            >
              {capitalizeFirstLetter(selectedTemplate?.category)}
            </p>
            <p
              className={`px-[0.6vw] py-[0.2vw] font-xs weight-semibold rounded-[0.25vw] bg-[#E7F4FC] text-[var(--dashboardprimary)]`}
            >
              {getLanguageName(selectedTemplate?.language)}
            </p>
          </div>
        ) : (
          <SearchBar
            searchKey={searchKey}
            setSearchKey={setSearchKey}
            className="!w-[15vw]"
            onChange={(val) => {
              onSearch(val);
            }}
          />
        )}
      </div>
      {loading && (
        <div className="w-full h-full min-h-[50vh] max-h-[73vh] flex items-center justify-center">
          <Loader Width={30} Height={30} loaderBg="white" />
        </div>
      )}
      {!loading && (
        <div className="">
          <div className="min-w-full flex gap-[1vw]">
            <div className="w-[65%] h-[58vh] pr-[0.2vw]">
              {useTemplates ? (
                <div className="w-full">
                  <div className="w-full h-[40vh] px-[0.5vw]">
                    <div>
                      <div className="w-full flex items-center gap-[1vw] py-[0.5vw] border-b-[0.08vw] border-[var(--border-50)]">
                        <div className="w-[40%] font-md weight-semibold">
                          Variable
                        </div>
                        <div className="w-[60%] font-md weight-semibold">
                          Add variable content
                        </div>
                      </div>
                      {bodyVariables?.length > 0 ? (
                        <div className="w-full h-[35vh] py-[0.5vw] overflow-y-scroll listScroll ">
                          {bodyVariables?.map((v) => {
                            return (
                              <div>
                                <VariablesPreviewCard
                                  key={v?.id}
                                  data={v}
                                  className={"h-[6.7vh] pr-[0.5vw]"}
                                  changeVariableText={changeVariable}
                                  onChange={(d, value) => {
                                    templateDispatch({
                                      type: "update_variable",
                                      variableTitle: d.title,
                                      variableValue: value,
                                    });
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="w-full h-[35vh] flex-center font-md weight-medium text-[var(--font-600)]">
                          No variables found on this template.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-full h-[15vh] pt-[1vw] border-t-[0.08vw] border-[var(--border-50)]">
                    <h3 className="font-normal weight-bold text-[var(--contentText)]">
                      Select Media File
                    </h3>
                    <p className="font-md weight-medium text-[var(--font-400)] mt-[0.3vw]">
                      Upload a media for this template
                    </p>
                    {["IMAGE", "VIDEO", "DOCUMENT"]?.includes(
                      header?.format
                    ) ? (
                      <div className="mt-[0.7vw]">
                        <div className="flex items-center gap-[0.8vw]">
                          <div>
                            {header?.format === "IMAGE" && (
                              <img
                                src={header?.example?.header_handle?.[0]}
                                alt="preview images"
                                className="size-[4vw] font-xs weight-medium rounded-[0.5vw]"
                              />
                            )}
                            {header?.format === "VIDEO" && (
                              <div className="h-[4vw] flex items-center gap-[0.3vw] bg-[var(--primary)] px-[0.5vw] py-[0.1.5vw] rounded-[0.5vw] cursor-pointer">
                                <VideoSvg
                                  color="var(--white)"
                                  width={"3vw"}
                                  height={"3vw"}
                                />
                              </div>
                            )}
                            {header?.format === "DOCUMENT" && (
                              <iframe
                                title="cover"
                                src={header?.example?.header_handle?.[0]}
                                type="application/pdf"
                                width="100%"
                                height="150px"
                              ></iframe>
                            )}
                          </div>
                          <div className="w-full">
                            <p className="w-3/4 font-md weight-semibold text-[var(--font-600)] truncate">
                              {selectedFile
                                ? selectedFile?.name
                                : fileName(header?.example?.header_handle?.[0])}
                            </p>
                            {changeMedia && (
                              <button className="w-[7vw] font-xs weight-bold bg-[var(--primary)] text-[var(--white)] px-[0.6vw] py-[0.4vw] rounded-[0.37vw] mt-[0.4vw]">
                                <div className="flex items-center justify-center">
                                  <input
                                    type="file"
                                    accept={
                                      header?.format === "IMAGE"
                                        ? ".jpg, .png"
                                        : header?.format === "VIDEO"
                                        ? ".mp4"
                                        : header?.format === "DOCUMENT" &&
                                          ".pdf"
                                    }
                                    onChange={async (e) => {
                                      const res = await uploadFiles(
                                        e,
                                        header?.format
                                      );
                                      updateTemplate("HEADER", "example", {
                                        header_handle: [res],
                                      });
                                    }}
                                    id="uploadImg"
                                    hidden
                                  />

                                  <label
                                    for="uploadImg"
                                    className="w-full flex-center"
                                  >
                                    {uploading ? (
                                      <Loader
                                        Width={20}
                                        Height={20}
                                        loaderBg="white"
                                      />
                                    ) : (
                                      <div className={`${uploadContainer}`}>
                                        Choose media
                                      </div>
                                    )}
                                  </label>
                                </div>
                              </button>
                            )}
                          </div>
                        </div>
                        <>
                          {header?.format === "IMAGE" && error && (
                            <div className={`${errorStyle}`}>
                              Image size should be less than 5MB
                            </div>
                          )}

                          {header?.format === "VIDEO" && error && (
                            <div className={`${errorStyle}`}>
                              Video size should be less than 16MB
                            </div>
                          )}

                          {header?.format === "DOCUMENT" && error && (
                            <div className={`${errorStyle}`}>
                              Document size should be less than 100MB
                            </div>
                          )}
                        </>
                      </div>
                    ) : (
                      <div className="w-full h-[10vh] flex-center font-md weight-medium text-[var(--font-600)]">
                        No media files found on this template.
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className={`min-w-full h-full overflow-hidden overflow-y-scroll`}
                  id="scrollableDiv"
                  ref={templateListContainerRef}
                >
                  <InfiniteScroll
                    dataLength={templates?.length}
                    next={() => {
                      loadNextPage();
                    }}
                    hasMore={templates?.length < count}
                    scrollableTarget="scrollableDiv"
                    height={isLoadingNextPage ? "53vh" : "58vh"}
                    className="!pr-[0.2vw] listScroll"
                  >
                    {templates?.map((t) => {
                      return (
                        <div className="w-full py-[0.5vw] first:pt-0 last:pb-[0.1vw]">
                          <TemplateCard
                            data={t}
                            selected={
                              t?.message_template_id ===
                                selectedTemplate?.message_template_id &&
                              t?.name === selectedTemplate?.name &&
                              t?.language === selectedTemplate?.language &&
                              t?.category === selectedTemplate?.category
                            }
                            handlePreviewSelect={(value) => {
                              setSelectedTemplate(value);
                            }}
                            useTemplate={() => setUseTemplates(true)}
                            className={"hover:bg-slate-100"}
                          />
                        </div>
                      );
                    })}
                    {isLoadingNextPage && (
                      <div className="w-full h-[5vh] flex items-center justify-center">
                        <Loader Width={30} Height={30} loaderBg="white" />
                      </div>
                    )}
                  </InfiniteScroll>
                </div>
              )}
            </div>

            <div className={`w-[35%] h-[58vh]`}>
              {selectedTemplate ? (
                // <TemplatePreview data={selectedTemplate} drawerType='medium' />
                <TemplatePreview
                  header={header}
                  headerVariable={headerVariable}
                  body={body?.text}
                  bodyVariables={bodyVariables}
                  footer={footer}
                  buttons={buttons}
                  buttonsOrder={buttonsOrder}
                  ctaButtons={ctaButtons}
                  customButtons={customButtons}
                  selectedTemplate={selectedTemplate}
                  useTemplates={useTemplates}
                  drawerType="medium"
                  carousel={carousel}
                  authentication={authentication}
                  category={selectedTemplate?.subcategory_type}
                />
              ) : (
                <div className="w-full h-full bg-[var(--BG-25)] flex items-center justify-center rounded-[1vw]">
                  <NoData
                    contentClassName={"max-w-[65%]"}
                    icon={ICONS?.NoTemplateSelected}
                    content={"No template is selected for preview"}
                  />
                </div>
              )}
            </div>
          </div>
          {useTemplates && (
            <div
              className={`w-full h-[10vh] flex items-center justify-end gap-[0.6vw] ${
                isSending ? "cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              <button
                className="w-[4vw] font-md weight-semibold px-[0.5vw] py-[0.3vw] rounded-[0.37vw] bg-[var(--primary)] text-[var(--white)]"
                onClick={() => {
                  if (!isSending) {
                    const body = selectedTemplate?.components?.filter(
                      (c) => c?.type === "BODY"
                    );
                    const withoutBody = selectedTemplate?.components?.filter(
                      (c) => c?.type !== "BODY"
                    );

                    let bodyObj = null;
                    if (variables?.length > 0) {
                      bodyObj = {
                        ...body?.[0],
                        example: {
                          body_text: variables?.map((v) => {
                            return v.value;
                          }),
                        },
                      };
                    } else {
                      bodyObj = body?.[0];
                    }

                    if (bodyObj) {
                      const newData = {
                        ...selectedTemplate,
                        components: [...withoutBody, bodyObj],
                      };

                      sendTemplate(newData);
                    }
                  }
                }}
              >
                {isSending ? (
                  <div className="w-full flex items-center justify-center">
                    <Loader Width={20} Height={20} loaderBg="white" />
                  </div>
                ) : (
                  <>Submit</>
                )}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
