import React, { useState, useEffect, useReducer } from "react";
import "./appLayout.css";

import { useNavigate, useLocation, useParams } from "react-router-dom";
import { navigationConst } from "../../constant/app/appLayout";

import Home from "../../assets/customSVG/navHome";
import Channels from "../../assets/customSVG/navChannels";
import Campaigns from "../../assets/customSVG/navCampaign";
import Calendar from "../../assets/customSVG/navCalendar";
import Inbox from "../../assets/customSVG/navInbox";
import CRM from "../../assets/customSVG/navCrm";
import Settings from "../../assets/customSVG/Settings";

import Avatar from "../../components/commonComponents/avatar/Avatar";
import { useAspDispatch, useAspSelector } from "../../test/jest-redux-hooks";
import { setPageNavigate } from "../../reduxToolkit/CampaignSlice";
import { ICONS } from "../../assets/icons";
import NewAlert from "../../components/commonComponents/modal/alert/NewAlert";

import { Modal } from "antd";
import {
  carouselDiscard,
  templateDiscard,
} from "../../modules/channels/api/Api";
import { updateToggleToast } from "../../reduxToolkit/appSlice";
import { draftApi } from "../../modules/campaign/api/Api";

// style
const avatarStyle = {
  height: "38px",
  width: "38px",
  background: "#ffe6b3",
  color: "var(--textBlack)",
  fontSize: "1.2vw",
  fontWeight: " 400 !important",
};

function SideBar({ isModalVisible, setIsModalVisible }) {
  const [navList, setNavList] = useState(navigationConst);
  const dispatch = useAspDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [discardAlertOpen, setDiscardAlertOpen] = useState(false);
  const [draftAlertOpen, setDraftAlertOpen] = useState(false);

  const location = useLocation();
  const templateDetails = useAspSelector((state) => state?.Template);
  const { currentBrand, toggleToast } = useAspSelector((state) => state?.app);
  const { saveBroadcastDatas } = useAspSelector((state) => state.broadcast);

  const { id } = useParams();
  const header = id;

  const discardCarousel = () => {
    if (
      location.pathname ===
        "/user/channels/whatsapp/edit-template/template-details" &&
      templateDetails?.categoryType === "CAROUSEL"
    ) {
      const payLoad = {
        brand_id: currentBrand?.brand_id,
      };

      carouselDiscard(templateDetails?.id, payLoad).then((res) => {
        if (res?.status === 200) {
          setDiscardAlertOpen(false);
          navigate("/user/channels/whatsapp/message-templates");
        }
      });
    } else if (
      location.pathname ===
        "/user/channels/whatsapp/edit-template/template-details" &&
      templateDetails?.categoryType !== "CAROUSEL"
    ) {
      const payLoad = {
        brand_id: currentBrand?.brand_id,
      };

      templateDiscard(templateDetails?.id, payLoad).then((res) => {
        if (res?.status === 200) {
          setDiscardAlertOpen(false);
          navigate("/user/channels/whatsapp/message-templates");
        }
      });
    } else {
      if (saveBroadcastDatas?.broadcast_id !== null) {
        draftApi(saveBroadcastDatas).then((createCampaignResponse) => {
          if (createCampaignResponse?.status === 200) {
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: "Broadcast saved successfully",
                  status: "Success",
                  duration: "",
                },
              ])
            );
            setDraftAlertOpen(false);
            navigate("/user/campaign/all");
          } else {
            setDraftAlertOpen(false);

            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content:
                    createCampaignResponse?.response?.data?.error ||
                    "Unable to save Broadcast",
                  status: "Error",
                  duration: "",
                },
              ])
            );
          }
        });
      }
    }
  };

  useEffect(() => {
    updateNavListState(pathname);
  }, [pathname]);

  const navigateModule = (item) => {
    if (
      location.pathname !==
        "/user/channels/whatsapp/edit-template/template-details" &&
      location.pathname !== `/user/campaign/time_specified/settings/${header}`
    ) {
      updateNavListState(item?.routeUrl);
      item?.navName === "Channels"
        ? navigate("/user/channels/whatsapp")
        : item?.navName === "Home"
        ? navigate("/user/home")
        : navigate(`.${item?.routeUrl}`);
    } else if (
      location.pathname ===
        "/user/channels/whatsapp/edit-template/template-details" &&
      (templateDetails?.details?.status === "PAM" ||
        templateDetails?.details?.status === "APPROVED" ||
        templateDetails?.details?.status === "REJECTED" ||
        templateDetails?.details?.status === "PRM")
    ) {
      setDiscardAlertOpen(true);
    } else if (
      location.pathname ===
        `/user/campaign/time_specified/settings/${header}` &&
      saveBroadcastDatas?.broadcast_id !== null
    ) {
      setDraftAlertOpen(true);
    } else {
      updateNavListState(item?.routeUrl);
      item?.navName === "Channels"
        ? navigate("/user/channels/whatsapp")
        : item?.navName === "Home"
        ? navigate("/user/home")
        : navigate(`.${item?.routeUrl}`);
    }
  };
  const updateNavListState = (path) => {
    setNavList((prev) => {
      const newState = prev?.map((prevItem) => {
        if (
          path?.includes(prevItem?.routeUrl) ||
          (prevItem?.navName === "Campaigns" &&
            (path?.includes("/user/campaign/") ||
              path?.includes("/user/api") ||
              path?.includes("/user/ContactApi")))
        ) {
          return { ...prevItem, selected: true };
        } else {
          return { ...prevItem, selected: false };
        }
      });
      return newState;
    });
  };

  return (
    <nav className="side_bar_container flex-column space-between py-4  ">
      <div className="">
        {navList?.map((item) => (
          <div
            key={item?.id}
            // className="navBox"
            className={`navBox ${item?.selected ? "active-listText1" : ""}`}
            onClick={() => {
              navigateModule(item);
              if (item?.navName === "Campaigns") {
                dispatch(setPageNavigate("All Campaigns"));
              }
            }}
          >
            {item?.navName === "Home" && (
              <Home
                color={item?.selected ? "white" : "#2d3036"}
                width={"20"}
                height={"19"}
              />
            )}
            {item?.navName === "Campaigns" && (
              <Campaigns
                color={item?.selected ? "white" : "#2d3036"}
                width={"22"}
                height={"21"}
              />
            )}

            {item?.navName === "Channels" && (
              <Channels
                color={item?.selected ? "white" : "#2d3036"}
                width={"22"}
                height={"21"}
              />
            )}

            {item?.navName === "Calendar" && (
              <Calendar
                color={item?.selected ? "white" : "#2d3036"}
                width={"22"}
                height={"21"}
              />
            )}
            {item?.navName === "CRM" && (
              <CRM
                color={item?.selected ? "white" : "#2d3036"}
                width={"22"}
                height={"21"}
              />
            )}

            {item?.navName === "Inbox" && (
              <Inbox
                color={item?.selected ? "white" : "#2d3036"}
                width={"22"}
                height={"21"}
              />
            )}

            {item?.navName === "Settings" && (
              <Settings
                color={item?.selected ? "white" : "#2d3036"}
                width={"22"}
                height={"21"}
              />
            )}
            <span
              className="navName"
              style={{ color: item?.selected ? "white" : "#2d3036" }}
            >
              {item?.navName}
            </span>
          </div>
        ))}
      </div>

      <div className="flex-row justify-center align-center log_out ">
        <Avatar
          style={avatarStyle}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      </div>

      {/* {category() === "CAROUSEL" && ( */}
      <Modal
        // open={isDuplicateModal}
        footer={null}
        open={discardAlertOpen}
        className={"asp-modal-popup-small"}
        closable={false}
        centered={true}
        wrapClassName={"bg-[#00000095]"}

        // onCancel={handleCancel}
      >
        <NewAlert
          type="new"
          open={discardAlertOpen}
          icon={ICONS?.AlertIcon}
          setOpen={setDiscardAlertOpen}
          content={
            <div>
              <p>
                You have made some changes in the template. How would you like
                to proceed.
              </p>
            </div>
          }
          // title={"Restore Template?"}
          noName="Continue Editing"
          yesName="Discard"
          noButtonClassName="!font-md !weight-semibold"
          handleNo={() => {
            discardCarousel();
          }}
          handleYes={() => {
            setDiscardAlertOpen(false);
          }}
        />
      </Modal>

      <Modal
        // open={isDuplicateModal}
        footer={null}
        open={draftAlertOpen}
        className={"asp-modal-popup-small"}
        closable={false}
        centered={true}
        wrapClassName={"bg-[#00000095]"}

        // onCancel={handleCancel}
      >
        <NewAlert
          type="new"
          open={draftAlertOpen}
          icon={ICONS?.AlertIcon}
          setOpen={setDraftAlertOpen}
          content={
            <div>
              <p>What would you like to do with the broadcast?</p>
            </div>
          }
          // title={"Restore Broadcast?"}
          noName="Continue Editing"
          yesName="Draft"
          noButtonClassName="!font-md !weight-semibold"
          handleNo={() => {
            discardCarousel(header);
          }}
          handleYes={() => {
            setDraftAlertOpen(false);
          }}
        />
      </Modal>
      {/* )} */}
    </nav>
  );
}

export default SideBar;
