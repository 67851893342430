import React from 'react';
import { addLineBreake, replaceFormating } from '../../helper';

export default function ImageMessagePreview({ url, caption }) {
  return (
    <div className='flex flex-col'>
      <img src={url} className='w-full h-full object-cover rounded-[0.4vw]' />
      <p
        className='font-md weight-small px-[0.5vw] pt-[0.5vw] break-word'
        dangerouslySetInnerHTML={{
          __html: addLineBreake(replaceFormating(caption)),
        }}
      ></p>
    </div>
  );
}
