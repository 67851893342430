import React from 'react';
import { addLineBreake, replaceFormating } from '../../helper';

export default function VideoMessagePreview({ url, caption }) {
  return (
    <div className='flex flex-col gap-2.5'>
      <video
        className='object-cove rounded-[0.4vw]'
        controls
        autoplay='autoplay'
        // loop
      >
        <source src={url} type='video/mp4' />
      </video>

      <p
        className='font-md weight-small px-[0.5vw] pt-[0.5vw] break-words'
        dangerouslySetInnerHTML={{
          __html: addLineBreake(replaceFormating(caption)),
        }}
      ></p>
    </div>
  );
}
