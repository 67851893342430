import React, { useEffect, useReducer, useState } from "react";
import "../../styles/contact.css";

import { Modal, Skeleton, Tooltip } from "antd";
import Eye from "../../../../assets/customSVG/Eye";
import { ICONS } from "../../../../assets/icons";
import MessageStatus from "../BroadcastSetting/BroadcastView/MessageStatus";
import Avatar from "../../../../components/commonComponents/avatar/Avatar";
import TemplatePreview from "../../../../components/commonComponents/templates/TemplatePreview";
import { images } from "../../../../assets/images";
import {
  INITIAL_STATE,
  templateReducer,
} from "../../../channels/reducers/templateReducer";

const BrandStyle = {
  width: `calc(100% / 4)`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const allowedPreviewStatus = [
  "DRAFT",
  "APPROVED",
  "REJECTED",
  "IN_REVIEW",
  "PENDING",
  10,
  0,
];
const ApiContactTable = ({
  contactList,
  isFilter,
  selectedStatus,
  statusExists,
  isLoading,
}) => {
  const broadcastContactData = contactList?.conversations_contacts;
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const formatPhoneNumber = (phoneNumber) => {
    return `(+${phoneNumber?.slice(0, 2)}) ${phoneNumber?.slice(2)}`;
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleClick = (item) => {
    setSelectedTemplate(item);
    setIsModalVisible(true);
  };

  const [templateData, templateDispatch] = useReducer(
    templateReducer,
    INITIAL_STATE
  );
  const { custom, carousel, authentication } = templateData;

  const {
    header,
    headerVariable,
    body,
    bodyVariables,
    footer,
    buttons,
    buttonsOrder,
    ctaButtons,
    customButtons,
  } = custom;

  useEffect(() => {
    templateDispatch({
      type: "update_template",
      data: selectedTemplate,
    });
  }, [selectedTemplate]);

  return (
    <div className=" flex-column ">
      {isModalVisible && <div className="modal-overlay"></div>}

      <div className="contact_table flex-column">
        <Skeleton active loading={isLoading} rootClassName="w-[98.5%]">
          <div className="flex-row">
            <div className=" flex-row px-7 pb-2  pt-1 ">
              <span className=" header_names w-[15vw] "> First name</span>
              <span className=" header_names w-[13vw]">Last name</span>
            </div>
            <div
              className="flex-row contact_left_top "
              style={{
                width: "60%",
              }}
            >
              <p style={BrandStyle}>Mobile number</p>
              <p style={BrandStyle}>Template Preview</p>
              <p style={BrandStyle}>Contacted Time</p>
              <p style={BrandStyle}>Status</p>
            </div>
          </div>

          <div className="contact_data">
            {broadcastContactData?.map((item) => {
              return (
                ((selectedStatus?.length > 0 &&
                  selectedStatus?.includes(item?.status)) ||
                  selectedStatus?.length === 0) &&
                item?.to_number?.includes(isFilter) &&
                statusExists && (
                  <div
                    className="contact_table_wrapper flex-row align-center"
                    key={item?.id}
                  >
                    <div className=" flex-row align-center">
                      <div className=" w-[15vw] flex-row align-center">
                        <div className="">
                          {item?.role ? (
                            <Avatar
                              key={item?.id}
                              name={item?.role}
                              style={{
                                backgroundColor: item?.color,
                                color: "white",
                                padding: "2px",
                                fontSize: "22px",
                                fontWeight: "400",
                                borderRadius: "50%",
                                width: "38px",
                              }}
                            />
                          ) : (
                            <img src={ICONS?.NoImageIcon} />
                          )}
                        </div>
                        <span className=" header_right pl-2">
                          {item?.first_name === null
                            ? " -- "
                            : item?.first_name}
                        </span>
                      </div>
                      <span className=" header_right w-[15.5vw] pl-2">
                        {item?.last_name === null ? " -- " : item?.last_name}
                      </span>
                    </div>
                    <div
                      className="flex-row contact_left "
                      style={{
                        width: "70%",
                        display: "flex",
                      }}
                    >
                      {item?.to_number.length > 15 ? (
                        <Tooltip title={item?.to_number}>
                          <p
                            className="flex-row justify-center align-center"
                            style={BrandStyle}
                          >
                            {formatPhoneNumber(
                              item?.to_number.length > 15
                                ? `${item?.to_number.slice(0, 15)}...`
                                : item?.to_number
                            )}
                          </p>
                        </Tooltip>
                      ) : (
                        <p
                          className="flex-row justify-center align-center"
                          style={BrandStyle}
                        >
                          {formatPhoneNumber(
                            item?.to_number.length > 15
                              ? `${item?.to_number.slice(0, 15)}...`
                              : item?.to_number
                          )}
                        </p>
                      )}

                      <Tooltip title="Template Preview" trigger={["hover"]}>
                        <div
                          style={BrandStyle}
                          onClick={() => handleClick(item)}
                          className="pointer"
                        >
                          <Eye
                            width="1.2vw"
                            height="1.2vw"
                            color="var(--font-600)"
                          />
                        </div>
                      </Tooltip>

                      <Modal
                        open={isModalVisible}
                        footer={null}
                        // className={"campaign-modal"}
                        closable={true}
                        centered={true}
                        onCancel={handleCancel}
                        width="20%"
                        style={{ borderRadius: "20px !important" }}
                        destroyOnClose={true}
                      >
                        <div className="MessagePreview w-100 p-20">
                          <div className="TestMessage">
                            <p className="flex-row">Preview</p>
                          </div>
                          <div className="h-[6vh] flex items-center gap-[0.5vw] bg-[#077C70] rounded-t-[1.5vw] px-[0.6vw]">
                            <div className="size-[2.1vw] bg-[var(--white)] rounded-full flex items-center justify-center font-xs">
                              Logo
                            </div>
                          </div>
                          <TemplatePreview
                            header={header}
                            headerVariable={headerVariable}
                            body={body}
                            bodyVariables={bodyVariables}
                            footer={footer}
                            buttons={buttons}
                            buttonsOrder={buttonsOrder}
                            ctaButtons={ctaButtons}
                            customButtons={customButtons}
                            carousel={carousel}
                            authentication={authentication}
                            category={selectedTemplate?.subcategory_type}
                            data={selectedTemplate}
                            className={"rounded-none min-h-[45vh] max-h-[45vh]"}
                            drawerType="small"
                          />
                          <img
                            src={images?.TemplatePreviewBottom}
                            alt="send msg"
                            className="w-[17.35vw] h-[2.3vw] rounded-b-[1.5vw]"
                          />
                        </div>
                      </Modal>

                      <p className=" flex-row pl-20" style={BrandStyle}>
                        {new Intl.DateTimeFormat("en-IN", {
                          timeZone: "Asia/Kolkata",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "numeric",
                          minute: "2-digit",
                          hour12: true,
                        }).format(new Date(item?.send_at))}
                      </p>

                      <p className=" flex-row pl-20" style={BrandStyle}>
                        <MessageStatus status={item?.status} />
                      </p>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </Skeleton>
      </div>
    </div>
  );
};

export default ApiContactTable;
