import { configureStore, combineReducers } from '@reduxjs/toolkit';

import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import Campaign from './CampaignSlice';
import Sequence from './SequenceSlice';
import app from './appSlice';
import auth from './authSlice';
import broadcast from './broadcastSlice';
import Channel from './channelSlice';
import CRM from './crmSlice';
import dashBoard from './dashboardSlice';
import Template from './templateSlice';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [
    'app',
    'authentication',
    'Campaign',
    'Channel',
    'Template',
    'broadcast',
    'sequence',
  ],
  blacklist: ['register'],
};

const rootReducer = combineReducers({
  authentication: auth,
  dashBoard: dashBoard,
  // Calendar: Calendar,
  Campaign: Campaign,
  broadcast: broadcast,
  app: app,
  Channel: Channel,
  Template: Template,
  crm: CRM,
  sequence: Sequence,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
