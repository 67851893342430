import React, { useEffect, useState } from "react";

import "../../styles/Campaign.css";

import { images } from "../../../../assets/images";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";
import dayjs from "dayjs";
import Status from "../../../../components/commonComponents/tables/Status";
import { setPageNavigate } from "../../../../reduxToolkit/CampaignSlice";
import { Modal } from "antd";
import NewAlert from "../../../../components/commonComponents/modal/alert/NewAlert";
import { ICONS } from "../../../../assets/icons";
import { updateToggleToast } from "../../../../reduxToolkit/appSlice";
import { draftApi } from "../../api/Api";

const campaignType = {
  2: "Time Specified",
};

const CampaignHeader = ({ campaignDetails }) => {
  const { pageNavigate } = useAspSelector((state) => state.Campaign);
  const { saveBroadcastDatas } = useAspSelector((state) => state.broadcast);
  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);
  const [discardAlertOpen, setDiscardAlertOpen] = useState(false);

  const { id } = useParams();
  const header = id;
  const dispatch = useAspDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const discardCarousel = (header, item) => {
    if (saveBroadcastDatas?.broadcast_id !== null) {
      setDiscardAlertOpen(true);

      draftApi(saveBroadcastDatas).then(
        (createCampaignResponse) => {
          if (createCampaignResponse?.status === 200) {
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: "Broadcast saved successfully",
                  status: "Success",
                  duration: "",
                },
              ])
            );
            const currentPath = window.location.pathname;
            if (
              pageNavigate === "All Campaigns" ||
              pageNavigate === "Time Specified Campaigns"
            ) {
              const basePath = `/user/campaign/time_specified/settings/${header}`;
              const backPath = `/user/campaign/time_specified/${header}`;
              if (currentPath.startsWith(basePath)) {
                navigate(backPath, { state: { item } });
              } else if (currentPath.startsWith(backPath)) {
                navigate("/user/campaign/all");
                dispatch(setPageNavigate("All Campaigns"));
              }
            }
          } else {
            setDiscardAlertOpen(false);
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content:
                    createCampaignResponse?.response?.data?.error ||
                    "Unable to save Broadcast",
                  status: "Error",
                  duration: "",
                },
              ])
            );
          }
        }
      );
    } else {
      // Handle case where broadcast_id is null
      const currentPath = window.location.pathname;
      if (
        pageNavigate === "All Campaigns" ||
        pageNavigate === "Time Specified Campaigns"
      ) {
        const basePath = `/user/campaign/time_specified/settings/${header}`;
        const backPath = `/user/campaign/time_specified/${header}`;

        if (currentPath.startsWith(basePath)) {
          navigate(backPath, { state: { item } });
        } else if (currentPath.startsWith(backPath)) {
          navigate("/user/campaign/all");
          dispatch(setPageNavigate("All Campaigns"));
        } else {
          navigate("/user/campaign/all");
        }
      } else {
        const basePath = `/user/campaign/perpetual/${header}`;
        if (currentPath.startsWith(basePath)) {
          navigate("/user/campaign/perpetual");
        }
      }
    }
  };

  const handleBackClick = (header, item) => {
    if (
      saveBroadcastDatas?.broadcast_id !== null &&
      location.pathname === `/user/campaign/time_specified/settings/${header}`
    ) {
      setDiscardAlertOpen(true);
    } else {
      // Handle case when broadcast_id is null or not matching the path
      setDiscardAlertOpen(false);
      const currentPath = window.location.pathname;
      if (
        pageNavigate === "All Campaigns" ||
        pageNavigate === "Time Specified Campaigns"
      ) {
        const basePath = `/user/campaign/time_specified/settings/${header}`;
        const backPath = `/user/campaign/time_specified/${header}`;

        if (currentPath.startsWith(basePath)) {
          navigate(backPath, { state: { item } });
        } else if (currentPath.startsWith(backPath)) {
          navigate("/user/campaign/all");
          dispatch(setPageNavigate("All Campaigns"));
        } else {
          navigate("/user/campaign/all");
        }
      } else {
        const basePath = `/user/campaign/perpetual/${header}`;
        if (currentPath.startsWith(basePath)) {
          navigate("/user/campaign/perpetual");
        }
      }
    }
  };

  return (
    <>
      <div className="campaignHeader-container w-100 flex-row align-center">
        <div className="campaignHeader-wrapper flex-row  align-center">
          <div className="flex-row align-center mr-5 ml-5">
            <img
              src={images?.CampaignBackIcon}
              alt="backIcon"
              onClick={() => handleBackClick(header, campaignDetails)}
              className="mr-10 ml-10 pointer"
            />
          </div>

          <div className="border-left flex-column p-10">
            <div className="flex-row align-center ">
              <span
                className="campaignBorderLeft"
                style={{ background: campaignDetails?.campaign_color }}
              />
              <span className="campaignHeader-campaign">
                {campaignDetails?.campaign_name &&
                  campaignDetails.campaign_name.charAt(0).toUpperCase() +
                    campaignDetails.campaign_name.slice(1)}
              </span>
              <div className="Awareness flex-row align-center">
                <span className="">
                  {campaignType[campaignDetails?.campaign_type_id]}
                </span>
                <span className="pl-5 pr-5">
                  {pageNavigate === "Perpetual Campaigns" && "Perpetual"}
                </span>
              </div>
            </div>
            <div className="campaignUpdate flex-row ">
              <span>Last updated</span>
              <span className="pl-5">
                {dayjs(campaignDetails?.updated_at).format("MMM DD, hh:mm a")}
              </span>
            </div>
          </div>
        </div>
        <div className="flex-column ">
          {(location.pathname.includes("/user/campaign/time_specified/") ||
            location.pathname.includes("/user/campaign/perpetual/")) && (
            <div className="campaign-status flex-row align-center ">
              <Status status={campaignDetails?.status} />
            </div>
          )}
        </div>
      </div>

      <Modal
        // open={isDuplicateModal}
        footer={null}
        open={discardAlertOpen}
        className={"asp-modal-popup-small"}
        closable={false}
        centered={true}
        wrapClassName={"bg-[#00000095]"}

        // onCancel={handleCancel}
      >
        <NewAlert
          type="new"
          open={discardAlertOpen}
          icon={ICONS?.AlertIcon}
          setOpen={setDiscardAlertOpen}
          content={
            <div>
              <p>What would you like to do with the broadcast?</p>
            </div>
          }
          // title={"Restore Broadcast?"}
          noName="Continue Editing"
          yesName="Draft"
          noButtonClassName="!font-md !weight-semibold"
          handleNo={() => {
            discardCarousel(header, campaignDetails);
          }}
          handleYes={() => {
            setDiscardAlertOpen(false);
          }}
        />
      </Modal>
    </>
  );
};

export default CampaignHeader;
