import { getLanguageName } from "helper/setLanguageCode";
import { checkVariable, getVariablesFromStr } from "helper/templates";

import {
  allowedCtaButtonTypes,
  allowedCustomButtonTypes,
  getFirtstTypeOfButton,
} from "components/commonComponents/templates/Buttons/utils";

import { updateVariablesOrder } from "../components/templates";
import { HEADER_TYPES_INITIAL_VALUES } from "../constant/template";
import {
  formatCarouelDataFromAPI,
  updateCardButtons,
  updateCarouselButtons,
  updateCarouselCard,
  updateCarouselCards,
  updateCarouselVariables,
  updateCarouseOrder,
} from "../utils/carousel";
import {
  checkSpaceAtEnd,
  checkSpaceAtStart,
  filterData,
  formatAuthenticationDataFromAPI,
  formatCustomDataFromAPI,
  formateAuthenticationDataFromAPI,
  updateCustomButtons,
} from "../utils/common";
import {
  updateVariablesFromText,
  updateVariablesValue,
} from "../utils/variables";

export const INITIAL_STATE = {
  template_id: null,
  name: null,
  category: null,
  categoryType: null,
  language: null,
  status: null,

  custom: {
    header: null,
    headerVariable: null,
    body: "",
    bodyVariables: [],
    footer: null,
    buttons: [],
    ctaButtons: [],
    customButtons: [],
    buttonsOrder: null,
  },

  authentication: {
    body: "{{otp-number}} is your verification code. For your security, do not share this code.",
    codeDeliverySetup: "copy-code",
    zeroTabTerms: true,
    appSetup: [
      {
        id: 1,
        packageName: "",
        appSignatureHash: "",
      },
    ],
    content: {
      securityRecommendation: true,
      expiryTimeForCode: false,
      expiryTime: 10,
    },
    buttons: [
      {
        id: 1,
        label: "Copy code",
        placeholder: "Copy code",
        text: "Copy code",
        type: "OTP",
        otp_type: "COPY_CODE",
      },
    ],
    messageValidity: {
      validity: true,
      period: 10,
    },
  },

  carousel: {
    header: "IMAGE",
    button1: "CUSTOM",
    button2: "NONE",
    bodyVariables: [],
    cards: [],
  },
};

export const templateReducer = (state, action) => {
  const {
    type,
    primaryKey,
    secondaryKey,
    id,
    key,
    value,
    text,
    updateKey,
    data,
  } = action;

  const checkBody = state?.custom?.bodyVariables?.filter(
    (v) => v?.title === key
  );

  const checkSpace = (text) => {
    return text?.length !== 0 ? text?.toString()?.endsWith(" ") : true;
  };

  const getDataObject = (id) => data?.filter((c) => c?.id === id);
  const getCard = (id) => state?.carousel?.cards?.filter((c) => c?.id === id);

  switch (type) {
    case "update":
      if (primaryKey && !secondaryKey && key) {
        return {
          ...state,
          [primaryKey]: {
            ...state[primaryKey],
            [key]: value,
          },
        };
      } else if (primaryKey && secondaryKey && key) {
        return {
          ...state,
          [primaryKey]: {
            ...state[primaryKey],
            [secondaryKey]: {
              ...state[primaryKey][secondaryKey],
              [key]: value,
            },
          },
        };
      } else {
        state[key] = value;
      }

    case "category":
      return {
        ...state,
        ...data,
        custom: { ...INITIAL_STATE?.custom },
        carousel: { ...INITIAL_STATE?.carousel },
        authentication: { ...INITIAL_STATE?.authentication },
      };

    case "header":
      if (key === "NONE") state.custom.headerVariable = null;
      if (updateKey === "format") {
        return {
          ...state,
          custom: {
            ...state.custom,
            header: HEADER_TYPES_INITIAL_VALUES[value],
            headerVariable: null,
          },
        };
      }
      if (updateKey === "text") {
        const newvariables = updateVariablesFromText(
          text,
          [state.custom.headerVariable],
          state.custom.bodyVariables
        );

        if (newvariables?.length > 0) {
          return {
            ...state,
            custom: {
              ...state.custom,
              headerVariable: newvariables?.[0],
              header: {
                ...state.custom.header,
                text: text,
                example: {
                  header_text: [newvariables?.[0]?.value],
                },
              },
            },
          };
        }

        return {
          ...state,
          custom: {
            ...state.custom,
            headerVariable: null,
            header: {
              ...state.custom.header,
              text: text,
            },
          },
        };
      }
      if (updateKey === "variable") {
        const checkVariable = updateVariablesFromText(
          state.custom.header.text,
          state.custom.headerVariable,
          state.custom.bodyVariables
        );

        return {
          ...state,
          custom: {
            ...state.custom,
            headerVariable: {
              title: key,
              value: checkBody?.[0]?.value ?? "",
            },
            header: {
              ...state.custom.header,
              text: `${state?.custom?.header?.text}${
                checkSpace(state?.custom?.header?.text) ? key : ` ${key}`
              }`,
              example: {
                header_text: [value],
              },
            },
          },
        };
      }
      if (updateKey === "mediaUrl")
        return {
          ...state,
          custom: {
            ...state?.custom,
            header: {
              ...state?.custom?.header,
              example: {
                header_handle: [value],
              },
            },
          },
        };
      break;

    case "update_variable":
      const res = state?.custom?.bodyVariables?.map((v) => {
        if (v.title === key)
          return {
            ...v,
            value: value,
          };
        return v;
      });

      const bodyVariables = updateVariablesFromText(
        state.custom.body,
        res,
        state.custom.headerVariable
      );

      let newHeaderData = { ...state.custom };
      if (state.custom.headerVariable?.title === key) {
        newHeaderData = {
          header: {
            ...state.custom.header,
            example: {
              header_text: [value],
            },
          },
          headerVariable: {
            ...state.custom.headerVariable,
            value: value,
          },
        };
      }

      return {
        ...state,
        custom: {
          ...state.custom,
          ...newHeaderData,
          bodyVariables: bodyVariables,
        },
      };

    case "body":
      let variables;
      let headerVariables;

      if (updateKey === "custom") {
        variables = updateVariablesFromText(text, state.custom.bodyVariables, [
          state.custom.headerVariable,
        ]);
        const headerVar = updateVariablesFromText(
          state.custom.header?.text,
          [state.custom.headerVariable],
          state.custom.bodyVariables
        );

        headerVariables = headerVar?.[0];
      } else {
        variables = updateVariablesFromText(
          text,
          state.custom.bodyVariables,
          state.carousel.bodyVariables
        );
        headerVariables = updateVariablesFromText(
          state.custom.header?.text,
          state.custom.bodyVariables,
          state.custom.headerVariable
        );
      }

      return {
        ...state,
        custom: {
          ...state.custom,
          body: text,
          bodyVariables: variables,
          headerVariable: headerVariables,
        },
      };

    case "update_bodyVariables":
      let customBodyVariables;
      let custom = state.custom;

      if (updateKey === "custom") {
        customBodyVariables = updateVariablesValue(
          state?.custom?.bodyVariables,
          [state?.custom?.headerVariable],
          key,
          value
        );

        let headerVariables = updateVariablesFromText(
          state.custom.header?.text,
          state.custom.bodyVariables,
          [state.custom.headerVariable]
        );

        custom = {
          ...state.custom,
          headerVariable: headerVariables,
          bodyVariables: customBodyVariables,
        };
      }

      let carousel = state.carousel;
      if (updateKey === "carousel") {
        let carouselBodyVariable = updateVariablesValue(
          state?.carousel?.bodyVariables,
          state?.custom?.bodyVariables,
          key,
          value
        );

        customBodyVariables = updateVariablesValue(
          state?.custom?.bodyVariables,
          [state?.custom?.headerVariable],
          key,
          value
        );

        custom = {
          ...state.custom,
          bodyVariables: customBodyVariables,
        };

        carousel = {
          ...state.carousel,
          bodyVariables: carouselBodyVariable,
        };
      }
      return {
        ...state,
        custom: custom,
        carousel: carousel,
      };

    case "footer":
      // if (text)
      return {
        ...state,
        custom: {
          ...state.custom,
          footer: {
            type: "FOOTER",
            text: text,
          },
        },
      };
    // else
    // return { ...state, footer: null };

    case "buttons":
      let newButtons = [];
      let footer = state.custom.footer;
      if (updateKey === "add") {
        newButtons = [...state.custom.buttons, data];
      } else if (updateKey === "update") {
        newButtons = state.custom.buttons.map((b) => {
          if (b.id === data?.id) return { ...b, [data?.key]: data?.value };
          return b;
        });
      } else if (updateKey === "remove") {
        newButtons = state.custom.buttons.filter((b) => b.id !== data?.id);
      } else if (updateKey === "replace") {
        newButtons = state.custom.buttons.map((b) => {
          if (b.id === data?.id) {
            if (b?.format === "OPT_OUT" && data?.format === "CUSTOM")
              footer = null;
            return data;
          }
          return b;
        });
      } else newButtons = state.custom.buttons;

      const { buttons, ctaButtons, customButtons, buttonsOrder } =
        updateCustomButtons({
          buttons: newButtons,
          data: data,
          updateKey: updateKey,
          buttonsOrder: state.custom.buttonsOrder,
        });

      return {
        ...state,
        custom: {
          ...state.custom,
          buttons: buttons,
          ctaButtons: ctaButtons,
          customButtons: customButtons,
          buttonsOrder: buttonsOrder,
          footer: footer,
        },
      };

    case "carousel":
      if (updateKey === "add_card") {
        const newCards = [...state.carousel.cards, data];
        const res = updateCarouselCards(newCards, data?.id, "isSelected");
        return {
          ...state,
          carousel: {
            ...state.carousel,
            cards: res,
          },
        };
      } else if (updateKey === "update_card") {
        const { cards, bodyVariables } = updateCarouselCard(
          state.carousel.cards,
          state.custom.bodyVariables,
          state.carousel.bodyVariables,
          id,
          key,
          value,
          data
        );
        return {
          ...state,
          carousel: {
            ...state.carousel,
            cards: cards,
            bodyVariables: bodyVariables,
          },
        };
      } else if (updateKey === "remove_card") {
        let cards = [];
        data?.map((c) => {
          const card = getCard(c?.id);
          if (card?.length > 0)
            cards?.push({ ...card?.[0], display_order: c?.display_order });
        });

        const updated = updateCarouseOrder(cards);
        return {
          ...state,
          carousel: {
            ...state.carousel,
            cards: updated?.map((c, i) => {
              if (i === 0) return { ...c, isSelected: true };
              return { ...c, isSelected: false };
            }),
          },
        };
      } else if (updateKey === "select_card") {
        return {
          ...state,
          carousel: {
            ...state?.carousel,
            cards: updateCarouselCards(
              state?.carousel?.cards,
              id,
              "isSelected"
            ),
          },
        };
      } else if (updateKey === "carousel_header_format") {
        return {
          ...state,
          carousel: {
            ...state.carousel,
            header: value,
            cards: updateCarouselCards(state.carousel.cards, null, "image", ""),
          },
        };
      } else if (updateKey === "update_card_button") {
        const res = state.carousel.cards?.map((c) => {
          if (c.id === id) {
            c.buttons = c.buttons?.map((b) => {
              if (b.id === data?.id) return { ...b, ...data };
              return b;
            });
          }
          return c;
        });

        return {
          ...state,
          carousel: {
            ...state.carousel,
            cards: res,
          },
        };
      } else if (updateKey === "update_all_buttons") {
        const { button1, button2, cards } = updateCarouselButtons(
          state.carousel.cards,
          state.carousel.button1,
          state.carousel.button2,
          key,
          value
        );
        return {
          ...state,
          carousel: {
            ...state.carousel,
            button1: button1,
            button2: button2,
            cards: cards,
          },
        };
      }

    case "athentication":
      if (updateKey === "appSetup_add")
        return {
          ...state,
          authentication: {
            ...authentication,
            appSetup: value,
          },
        };
      else if (updateKey === "content")
        return {
          ...state,
          authentication: {
            ...state.authentication,
            body: text,
            content: {
              ...state.authentication.content,
              [key]: value,
            },
          },
        };
      else if (updateKey === "update_buttons")
        return {
          ...state,
          authentication: {
            ...state.authentication,
            buttons: state?.authentication?.buttons?.map((b) => {
              if (id === b.id) return { ...b, [key]: value };
              return b;
            }),
          },
        };
      break;

    case "update_template":
      const basicDetails = {
        language: getLanguageName(data?.language),
        name: data?.name,
        category: data?.category,
        categoryType: data?.subcategory_type,
        template_id: data?.id,
        status: data?.status,
      };

      if (data?.subcategory_type === "AUTHENTICATION") {
        const authentication = formateAuthenticationDataFromAPI(data);
        return {
          ...state,
          ...basicDetails,
          authentication: {
            ...state.authentication,
            ...authentication,
          },
        };
      } else if (
        data?.subcategory_type === "UTILITY" ||
        data?.subcategory_type === "CUSTOM"
      ) {
        const customData = formatCustomDataFromAPI(data);

        return {
          ...state,
          ...basicDetails,
          custom: {
            ...state.custom,
            ...customData,
          },
        };
      } else if (data?.subcategory_type === "CAROUSEL") {
        const { body, bodyVariables, carousel } =
          formatCarouelDataFromAPI(data);
        return {
          ...state,
          ...basicDetails,
          custom: {
            ...state.custom,
            body: body,
            bodyVariables: bodyVariables,
          },
          carousel: carousel,
        };
      }

    case "reset":
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
