import React from 'react';
import { addLineBreake, replaceFormating } from '../../helper';

export default function DocumentMessagePreview({ url, caption }) {
  return (
    <div className='flex flex-col gap-2.5'>
      <iframe
        title='cover'
        className='imagePreview'
        src={url}
        type='application/pdf'
        width='100%'
        height='150px'
      ></iframe>
      <p
        className='font-md weight-small px-[0.5vw] pt-[0.5vw] break-words'
        dangerouslySetInnerHTML={{
          __html: addLineBreake(replaceFormating(caption)),
        }}
      ></p>
    </div>
  );
}
