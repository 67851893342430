import { images } from 'assets/images';

export const TYPES = [
  {
    id: 1,
    icon: '',
    label: 'Broadcast',
    key: 'broadcast',
    isSelected: false,
  },
  {
    id: 2,
    icon: '',
    label: 'Sequence',
    key: 'sequence',
    isSelected: false,
  },
];

export const STATUS = [
  {
    id: 1,
    icon: '',
    label: 'Draft',
    key: 'draft',
    status: 0,
    isSelected: false,
  },
  {
    id: 2,
    icon: '',
    label: 'Scheduled',
    key: 'scheduled',
    status: 10,
    isSelected: false,
  },
  {
    id: 3,
    icon: '',
    label: 'Active',
    key: 'active',
    status: 10,
    isSelected: false,
  },
  {
    id: 4,
    icon: '',
    label: 'In-active',
    key: 'in-active',
    status: 10,
    isSelected: false,
  },
  {
    id: 5,
    icon: '',
    label: 'Running',
    key: 'running',
    status: 11,
    isSelected: false,
  },
  {
    id: 6,
    icon: '',
    label: 'Completed',
    key: 'completed',
    status: 12,
    isSelected: false,
  },
];

export const SORT = [
  {
    id: 1,
    icon: '',
    label: 'Newest first',
    key: 'newest',
    isSelected: false,
  },
  {
    id: 2,
    icon: '',
    label: 'Oldest first',
    key: 'oldest',
    isSelected: false,
  },
  {
    id: 3,
    icon: '',
    label: 'Ascending',
    key: 'ascending',
    isSelected: false,
  },
  {
    id: 4,
    icon: '',
    label: 'Descending',
    key: 'descending',
    isSelected: false,
  },
  {
    id: 5,
    icon: '',
    label: 'Starred',
    key: 'starred',
    isSelected: false,
  },
];

export const CREATION_TYPES = [
  {
    id: 1,
    label: 'Broadcast',
    key: 'broadcast',
    icon: images?.Broadcast,
    link: '',
  },
  {
    id: 2,
    label: 'Sequence',
    key: 'sequence',
    icon: images?.Sequence,
    link: '',
  },
];
