import { Modal, Popover } from 'antd';
import React, { useEffect, useState } from 'react';

import { createCampaignApi } from '../../../api/apiClients/campaign';

// helpers
import { cn } from 'helper/cn';
import { CAMPAIGN_COLORS, getRandomColors } from '../../../helper/colors';

// constants
import {
  CHANNEL_LIST,
  GOAL_TARGET_TYPES,
  GOALS_LIST,
} from '../../../constant/app/campaigns/CreateCampaigns';

// components
import Header from '../modals/Header';
import useToggle from '../../../hooks/useToggle';
import ColorsAndEmojiIndegators from './ColorsAndEmojiIndegators';
import Select from '../select';
import MonthCalender from '../calendar/MonthCalender';
import moment from 'moment';
import { useAspDispatch, useAspSelector } from '../../../test/jest-redux-hooks';

const initial_data = {
  name: '',
  indicator: getRandomColors(CAMPAIGN_COLORS),
  indicator_type: 'Colors',
  channel: '',
  goal: '',
  goal_target: '',
  goal_target_type: GOAL_TARGET_TYPES[0],
  duration: {
    start: moment().toDate(),
    end: moment().toDate(),
  },
};

export default function CreateCampaignModal({
  type = 'create',
  open,
  setOpen,
  data = null,
}) {
  const { currentBrand } = useAspSelector((state) => state.app);
  const dispatch = useAspDispatch();

  const [isIndicatorOpen, setIsIndicatorOpen] = useToggle(false);
  const [isGoalTargetOpen, setIsGoalTargetOpen] = useToggle(false);

  const [campaignData, setCampaignData] = useState(null);

  useEffect(() => {
    if (open) {
      const INITIAL_DATA = {
        name: '',
        indicator: getRandomColors(CAMPAIGN_COLORS),
        indicator_type: 'Colors',
        channel: '',
        goal: '',
        goal_target: '',
        goal_target_type: GOAL_TARGET_TYPES[0],
        duration: {
          start: moment().toDate(),
          end: moment().toDate(),
        },
      };
      if (type === 'create') {
        setCampaignData(INITIAL_DATA);
      } else if (type === 'edit') {
        if (data) setCampaignData(data);
        else setCampaignData(INITIAL_DATA);
      }
    }
  }, [open]);

  const handleCancel = () => setOpen(false);
  const handleCancelIndecator = () => setIsIndicatorOpen(false);
  const handleCancelGoalTarget = () => setIsGoalTargetOpen(false);

  const onChange = (key, value) =>
    setCampaignData((prev) => {
      return { ...prev, [key]: value };
    });

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      campaign_name: campaignData?.name,
      campaign_color: campaignData?.indicator,
      start_date: campaignData?.duration?.start,
      end_date: campaignData?.duration?.end,
    };

    const res = createCampaignApi(currentBrand?.brand_id, payload);

    if (res.status === 200) {
    } else {
    }
  };

  // styles
  const inputContainer = 'w-full h-14 flex flex-col gap-2';
  const label = 'h-auto flex gap-1.5 text-sm weight-semibold';
  const require = 'text-red-400';

  return (
    <div>
      <Modal
        open={open}
        onCancel={handleCancel}
        afterOpenChange={(open) => {
          setOpen(open);
        }}
        width={'40vw'}
        height={'80vh'}
        centered
        footer={null}
        closable={false}
        wrapClassName={'bg-[#00000095]'}
        destroyOnClose={true}
      >
        <Header
          title='Create Campaign'
          subTitle='Build relationships with your audience through sequences.'
          titleStyle='text-2xl'
          subTitleStyle='text-sm'
          titleSize='none'
          subTitleSize='none'
          handleCancel={handleCancel}
        />
        <form
          className='px-5 py-4 flex flex-col gap-10'
          onSubmit={handleSubmit}
        >
          <div className={cn(inputContainer)}>
            <label className={cn(label)}>
              Name your campaign<span className={cn(require)}>*</span>
            </label>
            <div className='w-full h-11 flex items-center px-2.5 gap-2.5 border border-[var(--border-100)] rounded-lg'>
              <input
                type='text'
                className='w-10/12 h-[2.5rem] rounded-lg'
                name='name'
                value={campaignData?.name}
                placeholder='E.g. Spring sale offer'
                onChange={(e) => {
                  const { name, value } = e.target;
                  onChange(name, value);
                }}
              />
              <Popover
                trigger={['click']}
                placement='bottomRight'
                open={isIndicatorOpen}
                onOpenChange={(open) => {
                  setIsIndicatorOpen(open);
                }}
                content={
                  <ColorsAndEmojiIndegators
                    value={campaignData?.indicator}
                    onChange={(value) => {
                      handleCancelIndecator();
                      setCampaignData({
                        ...campaignData,
                        indicator: value?.data,
                        indicator_type: value?.type,
                      });
                    }}
                  />
                }
              >
                <div className='w-2/12 border-l border-[var(--border-100)] flex items-center justify-center gap-2.5'>
                  <div
                    className='px-2.5 flex items-center justify-center gap-2.5 cursor-pointer'
                    onClick={() => {
                      setIsIndicatorOpen(!setIsIndicatorOpen);
                    }}
                  >
                    {campaignData?.indicator_type === 'Emojis' ? (
                      <div className='size-6 contents-[""] text-lg flex items-center justify-center bg-[var(--BG-50)] rounded-sm'>
                        {campaignData?.indicator}
                      </div>
                    ) : (
                      <div
                        className='size-6 contents-[""] rounded-sm'
                        style={{
                          background: campaignData?.indicator,
                        }}
                      ></div>
                    )}
                    <div
                      className={cn(
                        'transition-transform duration-200 ease-linear',
                        isIndicatorOpen ? '-rotate-180' : '-rotate-0'
                      )}
                    >
                      <div
                        className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
                      ></div>
                    </div>
                  </div>
                </div>
              </Popover>
            </div>
          </div>

          <div className={cn(inputContainer)}>
            <label className={cn(label)}>
              Choose Channel<span className={cn(require)}>*</span>
            </label>
            <Select
              value={campaignData?.channel}
              options={CHANNEL_LIST}
              onChange={(data) => {
                if (data) onChange('channel', data);
              }}
              placeholder={'Select Channel'}
              rootClassName='w-full h-11 px-3 py-2.5'
              position='bottom'
            />
          </div>

          <div className={cn(inputContainer)}>
            <label className={cn(label)}>
              Select Goal<span className={cn(require)}>*</span>
            </label>
            <Select
              value={campaignData?.goal}
              options={GOALS_LIST}
              onChange={(data) => {
                if (data) onChange('goal', data);
              }}
              placeholder={'Select Goal'}
              rootClassName='w-full h-11 px-3 py-2.5'
              position='bottom'
            />
          </div>

          <div className={cn(inputContainer)}>
            <label className={cn(label)}>
              Goal Target<span className={cn(require)}>*</span>
            </label>
            <div className='w-full h-11 flex items-center px-2.5 gap-2.5 border border-[var(--border-100)] rounded-lg'>
              <input
                type='text'
                className='w-3/4 h-[2.5rem] rounded-lg'
                name='goal_target'
                value={campaignData?.goal_target}
                onChange={(e) => {
                  const { name, value } = e.target;
                  onChange(name, value);
                }}
              />
              <Popover
                trigger={['click']}
                placement='bottomRight'
                open={isGoalTargetOpen}
                onOpenChange={(open) => {
                  setIsGoalTargetOpen(open);
                }}
                content={
                  <div className='px-2.5 py-2.5'>
                    {GOAL_TARGET_TYPES?.map((t) => {
                      return (
                        <div
                          className={cn(
                            'mt-1.5 px-2.5 py-1.5 first:mt-0 hover:bg-gray-100 hover:cursor-pointer'
                          )}
                          onClick={() => {
                            handleCancelGoalTarget();
                            setCampaignData({
                              ...campaignData,
                              // goaltarget: value?.data,
                              goal_target_type: t,
                            });
                          }}
                        >
                          {t.label}
                        </div>
                      );
                    })}
                  </div>
                }
              >
                <div className='w-1/4 border-l border-[var(--border-100)] flex items-center justify-center gap-2.5'>
                  <div
                    className='px-2.5 flex items-center justify-center gap-2.5 cursor-pointer'
                    // onClick={() => {
                    //   setIsGoalTargetOpen(!isGoalTargetOpen);
                    // }}
                  >
                    <div>{campaignData?.goal_target_type?.label}</div>
                    {/* <div
                      className={cn(
                        'transition-transform duration-200 ease-linear',
                        isGoalTargetOpen ? '-rotate-180' : '-rotate-0'
                      )}
                    >
                      <div
                        className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
                      ></div>
                    </div> */}
                  </div>
                </div>
              </Popover>
            </div>
          </div>

          <div className={cn(inputContainer)}>
            <label className={cn(label)}>
              Duration<span className={cn(require)}>*</span>
            </label>
            <MonthCalender
              startDate={campaignData?.duration?.start}
              endDate={campaignData?.duration?.end}
              onChange={(data) => onChange('duration', data)}
              rootClassName='w-full justify-start bg-transparent border border-[var(--border-100)] rounded-lg'
              placement='bottom'
              primary={true}
              customRanges={false}
              allowedDate='next'
            />
          </div>

          <div className='w-full flex items-center justify-end px-5'>
            <button
              className={cn(
                'bg-[var(--primary)] text-white px-5 py-1.5 rounded-lg'
              )}
            >
              Create Campaign
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}
