import React, { useEffect, useState } from "react";
import "../styles/Campaign.css";

import { Outlet, useNavigate } from "react-router-dom";

import { campaignLeftList } from "../../../constant/app/campaign/campaign";

import LeftList from "../../../components/commonComponents/leftListLayout/Index";

import { useAspDispatch, useAspSelector } from "../../../test/jest-redux-hooks";
import {
  setPageNavigate,
  updateSelectedCampaignFilter,
} from "../../../reduxToolkit/CampaignSlice";
import { campaignListApi } from "../api/Api";

function Campaign({ children }) {
  const [filteredList, setFilteredList] = useState(campaignLeftList);
  const { currentBrand } = useAspSelector((state) => state.app);
  const { pageNavigate, selectedCampaignFilter } = useAspSelector(
    (state) => state.Campaign
  );
  const dispatch = useAspDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   dispatch(setPageNavigate("All Campaigns"));
  // }, []);

  // useEffect(() => {
  //   campaignListApi(currentBrand?.brand_id);
  // }, [currentBrand]);

  const campaignFilter = (item) => {
    dispatch(setPageNavigate(item?.name));
    navigate(`${item.route}`);
  };

  return (
    <div className="flex-row campaignWrapper">
      <LeftList
        list={filteredList}
        activeNav={pageNavigate}
        onSelect={(item) => campaignFilter(item)}
      />
      <div className="campaign-rightLayout">
        <Outlet />
        {children}
      </div>
    </div>
  );
}

export default Campaign;
