import React, { useEffect, useRef, useState } from 'react';

// assets
import { ICONS } from '../../../assets/icons';

// helpers
import { cn } from 'helper/cn';

// hooks
import useToggle from '../../../hooks/useToggle';

export default function Select({
  value,
  options,
  onChange,
  multiple = false,
  position = 'top',
  rootClassName = '',
  optionsClassName = '',
  optionClassName = '',
  labelClassName = '',
  cardStyle = '',
  cardList = false,
  clearAllIcon = false,
  dropDownIcon = true,
  placeholder = null,
  search = false,
  dropDownRender,
  allow = true,
  dataTestId,
  optionClick,
}) {
  const [selectedOption, setSelectedOption] = useState();
  const [isOpen, setIsOpen] = useToggle(false);
  const [highlightedIndex, setHighlightedIndex] = useState(null);
  const [searchKey, setSearchKey] = useState('');
  const containerRef = useRef(null);

  useEffect(() => {
    if (allow && isOpen) setHighlightedIndex(null);
  }, [isOpen]);

  useEffect(() => {
    if (Array.isArray(value)) {
      setSelectedOption(value);
    } else {
      if (typeof value === 'string') {
        setSelectedOption({
          label: value,
          value: value,
        });
      } else {
        setSelectedOption(value);
      }
    }
  }, [value]);

  const positions = {
    top: '!bottom-[calc(100%+0.5vw)]',
    bottom: '!top-[calc(100%+0.5vw)]',
  };

  const clearOptions = () => {
    if (onChange) multiple ? onChange([]) : onChange('');
  };

  const selectOption = (option) => {
    if (onChange) {
      if (multiple) {
        if (!value.includes(option)) onChange([...value, option]);
        // if (value.includes(option))
        //   onChange(value.filter((val) => val !== option));
        // else onChange([...value, option]);
      } else {
        if (option !== value) onChange(option);
        // if (value === option) onChange(null);
      }
    }
  };

  const isOptionSelected = (option) => {
    return multiple
      ? selectedOption?.includes(option)
      : option?.label === selectedOption?.label;
    // ||
    //     option?.name === selectedOption?.name;
  };

  // multiple select values card design
  const Card = ({ option }) => {
    return (
      <button
        key={option.value}
        onClick={(e) => {
          if (allow) {
            e.stopPropagation();
            selectOption(option);
          }
        }}
        className={`h-full w-auto flex items-center gap-[0.5vw] rounded-full px-[0.7vw] py-[0.3vw] bg-gray-100 transition-colors duration-100 ease-linear font-md weight-medium text-[var(--contentText)] ${cardStyle}`}
      >
        <div className='w-full group-hover:hidden flex items-center justify-center overflow-hidden text-ellipsis line-clamp-1'>
          {option?.label}
        </div>

        <div className='font-large weight-medium -mt-[0.1vw]'>&times;</div>
      </button>
    );
  };

  // useEffect(() => {
  //   const handler = (e) => {
  //     if (e.target != containerRef.current) return;
  //     switch (e.code) {
  //       case 'Enter':
  //       case 'Space':
  //         setIsOpen((prev) => !prev);
  //         if (allow && isOpen) selectOption(options[highlightedIndex]);
  //         break;
  //       case 'ArrowUp':
  //       case 'ArrowDown': {
  //         if (!isOpen) {
  //           setIsOpen(true);
  //           break;
  //         }

  //         const newValue = highlightedIndex + (e.code === 'ArrowDown' ? 1 : -1);
  //         if (allow && newValue >= 0 && newValue < options.length) {
  //           setHighlightedIndex(newValue);
  //         }
  //         break;
  //       }
  //       case 'Escape':
  //         setIsOpen(false);
  //         break;
  //     }
  //   };
  //   containerRef.current?.addEventListener('keydown', handler);

  //   return () => {
  //     containerRef.current?.removeEventListener('keydown', handler);
  //   };
  // }, [isOpen, highlightedIndex, options]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      ref={containerRef}
      tabIndex={0}
      className={`relative font-md weight-medium border-[0.08vw] border-[var(--border-100)] rounded-[0.4vw] flex items-center gap-[0.6vw] p-[0.5vw] outline-none ${
        allow ? 'cursor-pointer' : 'cursor-not-allowed'
      } ${rootClassName}`}
      onClick={() => {
        if (allow) setIsOpen(!isOpen);
      }}
      // onBlur={() => {
      // if (allow && containerRef?.current?.focus()) setIsOpen(false);
      // }}
      data-testid={dataTestId}
    >
      {(selectedOption || (multiple && selectedOption?.length > 0)) && (
        <div
          className={`flex-grow flex items-center gap-[0.5vw] text-[var(--contentText)] ${labelClassName}`}
        >
          {multiple ? (
            selectedOption?.map((v) => {
              return <Card option={v} />;
            })
          ) : cardList ? (
            <Card option={selectedOption} />
          ) : (
            selectedOption?.label
          )}
        </div>
      )}
      {(!selectedOption || (multiple && selectedOption?.length === 0)) && (
        <div className='w-full flex items-center font-md weight-medium text-[var(--font-300)]'>
          {placeholder ?? 'Please select'}
        </div>
      )}

      {clearAllIcon && (
        <button
          className={`font-largest border-none outline-none text-[var(--font-600)] ${
            allow ? 'cursor-pointer' : 'cursor-not-allowed'
          }`}
          onClick={(e) => {
            if (allow) {
              e.stopPropagation();
              clearOptions();
            }
          }}
        >
          &times;
        </button>
      )}
      {dropDownIcon && clearAllIcon && (
        <div
          className={`w-[0.1vw] self-stretch bg-[var(--border-100)] ${
            allow ? 'cursor-pointer' : 'cursor-not-allowed'
          }`}
        ></div>
      )}
      {dropDownIcon && (
        <div
          className={`transition-transform duration-200 ease-linear ${
            allow && isOpen ? '-rotate-180' : 'rotate-0'
          } ${allow ? 'cursor-pointer' : 'cursor-not-allowed'}`}
        >
          <div
            className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
          ></div>
        </div>
      )}
      <ul
        className={`w-full m-0 list-none max-h-[15vw] rounded-[0.4vw] flex-col absolute left-0 overflow-y-scroll listScroll bg-[var(--white)] z-50 p-[0.5vw] shadow-[0px_4px_30px_0px_#2D30361A] ${optionsClassName} ${
          positions[position]
        } ${allow && isOpen ? 'flex' : 'hidden'}`}
        onMouseLeave={() => {
          if (allow) setHighlightedIndex(null);
        }}
      >
        {options?.map((option, index) => {
          if (dropDownRender)
            return (
              <div
                className='cursor-pointer'
                onClick={(e) => {
                  if (allow) {
                    e.stopPropagation();
                    selectOption(option);
                    setIsOpen(false);
                  }
                }}
                onMouseEnter={() => {
                  if (allow) setHighlightedIndex(index);
                }}
              >
                {dropDownRender(option, selectOption)}
              </div>
            );

          return (
            <>
              {!option?.disabled && (
                <li
                  key={`${option.value}-${index}`}
                  className={cn(
                    'w-full p-[0.5vw] rounded-[0.3vw] font-md weight-medium cursor-pointer transition-colors duration-100 ease-linear text-[var(--contentText)] flex items-center justify-between',
                    highlightedIndex && highlightedIndex === index
                      ? 'bg-gray-200'
                      : '',
                    optionClassName,
                    option?.disabled
                      ? 'cursor-not-allowed'
                      : 'hover:bg-slate-100'
                  )}
                  onClick={(e) => {
                    // if (option?.disabled)
                    if (allow) {
                      e.stopPropagation();
                      selectOption(option);
                      setIsOpen(false);
                    }
                  }}
                  onMouseEnter={() => {
                    if (allow || !option?.disabled) setHighlightedIndex(index);
                  }}
                  data-testid={optionClick}
                >
                  {option?.label || option?.name || option?.profession_name_1}

                  {isOptionSelected(option) && (
                    <img
                      src={ICONS?.Selected}
                      alt='selected'
                      className='size-[1vw]'
                    />
                  )}
                </li>
              )}
            </>
          );
        })}
      </ul>
    </div>
  );
}

// import React, { useEffect, useRef, useState } from 'react';

// // assets
// import { ICONS } from '../../../assets/icons';

// // hooks
// import useToggle from '../../../hooks/useToggle';
// import { Popover } from 'antd';

// export default function Select({
//   value,
//   options,
//   onChange,
//   multiple = false,
//   position = 'top',
//   rootClassName = '',
//   optionsClassName = '',
//   labelClassName = '',
//   cardStyle = '',
//   cardList = false,
//   clearAllIcon = false,
//   dropDownIcon = true,
//   placeholder = null,
//   search = false,
//   dropDownRender,
// }) {
//   const [selectedOption, setSelectedOption] = useState();
//   const [isOpen, setIsOpen] = useToggle(false);
//   const [highlightedIndex, setHighlightedIndex] = useState(null);
//   const [searchKey, setSearchKey] = useState('');
//   const containerRef = useRef(null);

//   useEffect(() => {
//     if (isOpen) setHighlightedIndex(null);
//   }, [isOpen]);

//   useEffect(() => {
//     if (Array.isArray(value)) {
//       setSelectedOption(value);
//     } else {
//       if (typeof value === 'string') {
//         setSelectedOption({
//           label: value,
//           value: value,
//         });
//       } else {
//         setSelectedOption(value);
//       }
//     }
//   }, [value]);

//   const positions = {
//     top: '!bottom-[calc(100%+0.25vw)]',
//     bottom: '!top-[calc(100%+0.25vw)]',
//   };

//   const clearOptions = () => {
//     if (onChange) multiple ? onChange([]) : onChange('');
//   };

//   const selectOption = (option) => {
//     if (onChange) {
//       if (multiple) {
//         if (!value.includes(option)) onChange([...value, option]);
//         // if (value.includes(option))
//         //   onChange(value.filter((val) => val !== option));
//         // else onChange([...value, option]);
//       } else {
//         if (option !== value) onChange(option);
//         // if (value === option) onChange(null);
//       }
//     }
//   };

//   const isOptionSelected = (option) => {
//     return multiple
//       ? selectedOption?.includes(option)
//       : option?.label === selectedOption?.label;
//   };

//   // multiple select values card design
//   const Card = ({ option }) => {
//     return (
//       <button
//         key={option.value}
//         onClick={(e) => {
//           e.stopPropagation();
//           selectOption(option);
//         }}
//         className={`h-full w-auto flex items-center gap-[0.5vw] rounded-full px-[0.7vw] py-[0.3vw] bg-gray-100 transition-colors duration-100 ease-linear font-md weight-medium text-[var(--contentText)] ${cardStyle}`}
//       >
//         <div className='w-full group-hover:hidden flex items-center justify-center overflow-hidden text-ellipsis line-clamp-1'>
//           {option?.label}
//         </div>

//         <div className='font-large weight-medium -mt-[0.1vw]'>&times;</div>
//       </button>
//     );
//   };

//   useEffect(() => {
//     const handler = (e) => {
//       if (e.target != containerRef.current) return;
//       switch (e.code) {
//         case 'Enter':
//         case 'Space':
//           setIsOpen((prev) => !prev);
//           if (isOpen) selectOption(options[highlightedIndex]);
//           break;
//         case 'ArrowUp':
//         case 'ArrowDown': {
//           if (!isOpen) {
//             setIsOpen(true);
//             break;
//           }

//           const newValue = highlightedIndex + (e.code === 'ArrowDown' ? 1 : -1);
//           if (newValue >= 0 && newValue < options.length) {
//             setHighlightedIndex(newValue);
//           }
//           break;
//         }
//         case 'Escape':
//           setIsOpen(false);
//           break;
//       }
//     };
//     containerRef.current?.addEventListener('keydown', handler);

//     return () => {
//       containerRef.current?.removeEventListener('keydown', handler);
//     };
//   }, [isOpen, highlightedIndex, options]);

//   return (
//     <Popover
//       open={isOpen}
//       onOpenChange={(open) => {
//         setIsOpen(open);
//       }}
//       className={`font-md weight-medium border-[0.08vw] border-[var(--border-100)] rounded-[0.4vw] flex items-center gap-[0.6vw] p-[0.5vw] outline-none cursor-pointer ${rootClassName}`}
//       content={
//         <ul
//           className={`w-full m-0 list-none max-h-[15vw] rounded-[0.4vw] flex-col absolute left-0 overflow-y-scroll listScroll bg-[var(--white)] z-50 p-[0.5vw] shadow-[0px_4px_30px_0px_#2D30361A] ${optionsClassName}`}
//           onMouseLeave={() => {
//             setHighlightedIndex(null);
//           }}
//         >
//           {options?.map((option, index) => {
//             if (dropDownRender)
//               return (
//                 <div
//                   className='cursor-pointer'
//                   onClick={(e) => {
//                     e.stopPropagation();
//                     selectOption(option);
//                     setIsOpen(false);
//                   }}
//                   onMouseEnter={() => {
//                     setHighlightedIndex(index);
//                   }}
//                 >
//                   {dropDownRender(option, selectOption)}
//                 </div>
//               );

//             return (
//               <li
//                 key={option.value}
//                 className={`p-[0.5vw] rounded-[0.3vw] font-md weight-medium cursor-pointer transition-colors duration-100 ease-linear text-[var(--contentText)] hover:bg-slate-100 flex items-center justify-between ${
//                   highlightedIndex && highlightedIndex === index
//                     ? 'bg-gray-200'
//                     : ''
//                 }`}
//                 onClick={(e) => {
//                   e.stopPropagation();
//                   selectOption(option);
//                   setIsOpen(false);
//                 }}
//                 onMouseEnter={() => {
//                   setHighlightedIndex(index);
//                 }}
//               >
//                 {option.label}

//                 {isOptionSelected(option) && (
//                   <img
//                     src={ICONS?.Selected}
//                     alt='selected'
//                     className='size-[1vw]'
//                   />
//                 )}
//               </li>
//             );
//           })}
//         </ul>
//       }
//     >
//       <div
//         ref={containerRef}
//         tabIndex={0}
//         className={`relative font-md weight-medium border-[0.08vw] border-[var(--border-100)] rounded-[0.4vw] flex items-center gap-[0.6vw] p-[0.5vw] outline-none cursor-pointer ${rootClassName}`}
//         onClick={() => setIsOpen(!isOpen)}
//         onBlur={() => setIsOpen(false)}
//       >
//         {(selectedOption || (multiple && selectedOption?.length > 0)) && (
//           <div
//             className={`flex-grow flex items-center gap-[0.5vw] text-[var(--contentText)] ${labelClassName}`}
//           >
//             {multiple ? (
//               selectedOption?.map((v) => {
//                 return <Card option={v} />;
//               })
//             ) : cardList ? (
//               <Card option={selectedOption} />
//             ) : (
//               selectedOption?.label
//             )}
//           </div>
//         )}
//         {(!selectedOption || (multiple && selectedOption?.length === 0)) && (
//           <div className='w-full flex items-center font-md weight-medium text-[var(--font-300)]'>
//             {placeholder ?? 'Please select'}
//           </div>
//         )}

//         {clearAllIcon && (
//           <button
//             className={`font-largest border-none outline-none text-[var(--font-600)]`}
//             onClick={(e) => {
//               e.stopPropagation();
//               clearOptions();
//             }}
//           >
//             &times;
//           </button>
//         )}
//         {dropDownIcon && clearAllIcon && (
//           <div
//             className={`w-[0.1vw] self-stretch bg-[var(--border-100)]`}
//           ></div>
//         )}
//         {dropDownIcon && (
//           <div
//             className={`transition-transform duration-200 ease-linear ${
//               isOpen ? '-rotate-180' : 'rotate-0'
//             }`}
//           >
//             <div
//               className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
//             ></div>
//           </div>
//         )}
//       </div>
//     </Popover>
//   );
// }
