import React from "react";

// assets
import { ICONS } from "../../../../assets/icons";
import Delete from "../../../../assets/customSVG/Delete";

// utils
import { customButtonOptionConversion } from "./utils";

// hooks
import useToggle from "../../../../hooks/useToggle";

// components
import BtnInput from "./BtnInput";
// import Select from '../../../../components/form/select/selectCustom';
import Select from "components/commonComponents/select/index";
import { all } from "axios";

export default function QuickReplayBtn({
  data,
  updateButtons,
  options,
  allow = true,
}) {
  const [error, setError] = useToggle(false);

  const value = {
    label: customButtonOptionConversion[data?.format] ?? "Custom",
    value: customButtonOptionConversion[data?.format] ?? "Custom",
  };

  const initialData = {
    OPT_OUT: {
      format: "OPT_OUT",
      text: "Stop promotions",
      type: "QUICK_REPLY",
    },
    CUSTOM: {
      format: "CUSTOM",
      text: "",
      type: "QUICK_REPLY",
    },
  };

  return (
    <>
      <div className="w-full flex items-center justify-between gap-[0.6vw]">
        <div className="w-[10.3vw] h-[2vw] font-md weight-semibold">
          <Select
            value={value}
            options={options}
            wrapperClassName="!border-[0.08vw] !border-[var(--card-border)]"
            dropdownIcon={ICONS?.SelectDropdown}
            onChange={(value) => {
              if (allow) {
                updateButtons({
                  updateKey: "replace",
                  data: { id: data?.id, ...initialData[value?.type] },
                });
              }
            }}
          />
        </div>
        <div
          className={`flex-1 ${
            data?.format === "OPT_OUT"
              ? "w-[11.8vw] !text-[var(--font-600)]"
              : "w-[29.7vw]"
          }`}
        >
          <BtnInput
            value={data?.text ?? ""}
            count={25}
            onChange={(value) => {
              if (allow) {
                updateButtons({
                  updateKey: "update",
                  id: data?.id,
                  key: "text",
                  value: value,
                });
                if (value === "Stop promotions") setError(true);
                else setError(false);
              }
            }}
            error={error}
            disabled={data?.format === "OPT_OUT" ? true : false}
            onBlur={() => {
              // updateButtons({
              //   updatevalue: 'update',
              //   id: data?.id,
              //   key: 'text',
              //   value: data?.text,
              // });
              if (allow && data?.text === "Stop promotions") setError(true);
              else setError(false);
            }}
          />
        </div>
        {data?.format === "OPT_OUT" && (
          <div className="w-[17.3vw] h-[2vw] bg-[var(--BG-25)] text-[var(--font-600)] font-md weight-medium rounded-[0.4vw] flex items-center pl-[0.4vw] py-[0.4vw] border-[0.08vw] border-[var(--card-border)]">
            Not interested? Tap Stop promotions
          </div>
        )}

        <button
          className={`w-[1.5vw] h-[1.5vw] group bg-transparent flex items-center justify-center rounded-[0.4vw] ${
            allow ? "hover:bg-red-200" : ""
          }`}
          onClick={() => {
            if (allow)
              updateButtons({
                updateKey: "remove",
                id: data?.id,
              });
          }}
        >
          <Delete
            width="1.1vw"
            height="1.1vw"
            className={`stroke-[#898E99] ${
              allow ? " group-hover:stroke-red-500" : ""
            }`}
            color=""
          />
        </button>
      </div>
      {data?.format === "CUSTOM" && error && data?.text?.length > 0 && (
        <p className="font-sm weight-medium text-[var(--fontRed)] pt-[0.3vw] px-[0.5vw]">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit.
        </p>
      )}
    </>
  );
}
