import React, { useEffect, useRef, useState } from "react";

import "../../styles/Broadcast.css";
import "../../styles/BroadcastTable.css";

import { ICONS } from "../../../../assets/icons";

import BroadcastFilter from "../../../../components/commonComponents/ListViewHeader/BroadcastFilter";

import { Button } from "../../../../components/form/Button/Button";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { broadCastListApi, broadcastDeleteApi } from "../../api/Api";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";
import { Modal, Skeleton, Tooltip } from "antd";
import CustomDelete from "../../../../assets/customSVG/CustomDelete";
import Status from "../../../../components/commonComponents/tables/Status";
import AlertModal from "../../../../components/commonComponents/modal/alert/AlertModal";
import { updateToggleToast } from "../../../../reduxToolkit/appSlice";
import NoBroadcast from "./NoBroadcast";
import dayjs from "dayjs";
import useToggle from "../../../../hooks/useToggle";
import SearchBar from "../../../../components/commonComponents/searchBar";
import { BroadcastHeaderList } from "../../../../constant/app/campaign/Broadcast";
import Loader from "../../../../components/commonComponents/Loader/Index";

const tdStyle = {
  width: `calc(100% / 7)`,
  // padding: "0 0px 0 20px",
};

const BroadcastTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFilter, setIsFilter] = useState("");
  const [hover, setHover] = useState(false);
  const [broadcast, setBroadcast] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [page, setPage] = useState(1);
  const [isModelOpen, setIsModelOpen] = useState({});
  const [value1, toggleValue] = useToggle(false);
  const [count, setCount] = useState(0);

  const [isLoadingNextPage, setIsLoadingNextPage] = useToggle(false);

  const dispatch = useAspDispatch();
  const navigate = useNavigate();

  const { tableColumn1 } = useAspSelector((state) => state.Campaign);
  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);
  const columnLength = tableColumn1?.filter((item) => item?.selected)?.length;
  const { id } = useParams();
  const campaignId = id;
  const location = useLocation();

  const campaignDetails = location?.state?.item;

  const handleCancel = () => setIsModelOpen(false);

  const handleNo = () => {
    setIsModelOpen(false);
  };

  const handleNewBroadcastClick = (item) => {
    navigate(`/user/campaign/time_specified/settings/${id}`, {
      state: { data: item },
    });
  };

  const handleTableRow = (action, item) => {
    const itemId = item?.id;
    if (action === "delete") {
      setIsModelOpen((prevState) => ({
        ...prevState,
        [itemId]: true,
      }));
    } else if (action === "edit") {
      navigate(`/user/campaign/time_specified/settings/${id}`, {
        state: { data: campaignDetails, item: item },
      });
    } else {
      if (item?.status === 12 || item?.status === 11) {
        navigate(`/user/campaign/time_specified/segment_list/${item?.id}`, {
          state: { item },
        });
      }
    }
  };

  const fetchBroadCastLists = (load, brandId, campaignId, payload) => {
    if (brandId) {
      if (load) setIsLoading(true);
      if (!load) setIsLoadingNextPage(true);
      const newData = payload?.page === 1 ? [] : [...broadcast];
      broadCastListApi(brandId, campaignId, payload)
        .then((res) => {
          if (res && res?.data?.broadcasts?.length > 0) {
            newData.push(...res?.data?.broadcasts);
            setBroadcast(newData);
            setCount(res?.data?.broadcast_count);
            setPage(page + 1);
          }
        })
        .finally(() => {
          if (load) setIsLoading(false);
          if (!load) setIsLoadingNextPage(false);
        });
    } else {
      setBroadcast([]);
    }
  };

  const loadNextPage = () => {
    if (broadcast?.length < count) {
      fetchBroadCastLists(false, currentBrand?.brand_id, campaignId, {
        page: page,
        limit: 10,
      });
    }
  };

  const handleScroll = (e) => {
    if (!isLoadingNextPage) {
      const { scrollTop, clientHeight, scrollHeight } = e.target;

      if (scrollTop + clientHeight >= scrollHeight - 20) {
        loadNextPage();
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setPage(1);

    fetchBroadCastLists(true, currentBrand?.brand_id, campaignId, {
      page: 1,
      limit: 10,
      name: isFilter,
      status: selectedStatus?.length ? selectedStatus : undefined,
    });
  }, [campaignId]);

  const handleDelete = (item) => {
    toggleValue(true);
    broadcastDeleteApi(currentBrand?.brand_id, campaignId, item?.id).then(
      (res) => {
        if (currentBrand?.brand_id && campaignId) {
          if (res.status === 200) {
            setIsLoading(true);
            fetchBroadCastLists(true, currentBrand?.brand_id, campaignId);
            setIsModelOpen(false);

            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: "Broadcast deleted successfully",
                  status: "Success",
                  duration: "",
                },
              ])
            );
          }
          window?.location?.reload();
        }
      }
    );
  };

  const templateSearch = (value) => {
    setIsFilter(value);
  };

  const onFilterChange = (status) => {
    const updatedStatus = selectedStatus.includes(status)
      ? selectedStatus.filter((s) => s !== status)
      : [...selectedStatus, status];
    setSelectedStatus(updatedStatus);
    fetchBroadCastLists(true, currentBrand?.brand_id, campaignId, {
      page: 1,
      status: updatedStatus,
    });
  };

  const statusExists = broadcast?.some((item) => {
    if (selectedStatus.length > 0) {
      return selectedStatus.includes(item?.status);
    } else {
      return item?.name?.toLowerCase().includes(isFilter?.toLowerCase());
    }
  });
  const showSkeleton = isLoading && broadcast?.length === 0;

  return (
    <div className="w-auto flex-column">
      <div className=" w-100 pt-20">
        <span className="main-headings-broadcast">Broadcast</span>

        <div className="flex-row align-center space-between w-100">
          <div className="w-100 flex-row align-center space-between pt-10">
            <div className="w-[15vw]">
              <SearchBar
                searchKey={isFilter}
                setSearchKey={setIsFilter}
                onChange={(text) => templateSearch(text)}
                // searchingText={}
              />
            </div>
            <div className="flex-row align-center">
              {broadcast?.length > 0 && (
                <BroadcastFilter
                  onFilterChange={onFilterChange}
                  selectedStatus={selectedStatus}
                />
              )}

              {(campaignDetails?.status === 10 ||
                campaignDetails?.status === 11) && (
                <Button
                  label={"New Broadcast"}
                  size={"medium"}
                  onClick={() => handleNewBroadcastClick(campaignDetails)}
                  dataTestId="broad_button"
                />
              )}
            </div>
          </div>
        </div>
        <div
          className="tableWrapper1  mt-10 pl-4 mb-10 listScroll"
          style={{
            background: "var(--channelButton)",
          }}
          onScroll={handleScroll}
        >
          <Skeleton
            active
            loading={showSkeleton}
            showSkeleton={showSkeleton}
            rootClassName="w-[98.5%]"
          >
            {statusExists && broadcast?.length > 0 ? (
              <div className="w-full overflow-auto ">
                <table
                  style={{
                    padding: "0 16px 35px 0",
                    width:
                      columnLength > 6
                        ? `${600 + columnLength * 190}px`
                        : "100%",
                  }}
                >
                  <thead>
                    <tr className="tableHeaderRow1  top-0">
                      <th>
                        <span className="campaignName">Broadcast Name</span>
                      </th>

                      {BroadcastHeaderList?.map((column) => (
                        <React.Fragment key={column?.name}>
                          {column?.selected && <th>{column?.name}</th>}
                        </React.Fragment>
                      ))}
                    </tr>
                  </thead>
                  {broadcast?.map((item) => {
                    return (
                      ((selectedStatus?.length > 0 &&
                        selectedStatus?.includes(item?.status)) ||
                        selectedStatus?.length === 0) &&
                      item?.name
                        ?.toLowerCase()
                        ?.includes(isFilter?.toLowerCase()) &&
                      statusExists && (
                        <tbody key={item?.id}>
                          {isModelOpen[item?.id] && (
                            <div className="modal-overlay" />
                          )}
                          <tr className="listRow1 m-10">
                            <td>
                              <div className=" flex-column justify-center rounded-l-lg overflow-hidden">
                                <div className=" h-[25px] flex-row pl-5">
                                  <div className="flex-row align-center">
                                    {item?.name?.length > 30 ? (
                                      <Tooltip title={item?.name}>
                                        <span
                                          onClick={(event) => {
                                            handleTableRow(event, item);
                                          }}
                                          data-testid="view-page"
                                          className="pointer capitalize text-[#616874] truncate w-90"
                                        >
                                          {item?.name
                                            ? item.name
                                                .charAt(0)
                                                .toUpperCase() +
                                              item.name.slice(1)
                                            : ""}
                                        </span>
                                      </Tooltip>
                                    ) : (
                                      <span
                                        onClick={(event) => {
                                          handleTableRow(event, item);
                                        }}
                                        data-testid="view-page"
                                        className="pointer capitalize text-[#616874] truncate"
                                      >
                                        {item?.name
                                          ? item.name.charAt(0).toUpperCase() +
                                            item.name.slice(1)
                                          : ""}
                                      </span>
                                    )}

                                    <div
                                      className="broadcast-icons1 pointer ml-10 mt-5"
                                      onClick={() =>
                                        handleTableRow(
                                          "edit",
                                          item,
                                          campaignDetails
                                        )
                                      }
                                      data-testid="edit-broadcastClick"
                                    >
                                      {(item?.status === 0 ||
                                        item?.status === 10) &&
                                        campaignDetails?.status !== 12 && (
                                          <img src={ICONS?.edit} alt="edit" />
                                        )}
                                    </div>
                                    <div className="broadcast-icons1 pointer ml-10">
                                      {(item?.status === 0 ||
                                        item?.status === 10) && (
                                        <div
                                          onMouseOver={() => setHover(true)}
                                          onMouseLeave={() => setHover(false)}
                                          style={{
                                            background: hover
                                              ? "#FFEEED"
                                              : "transparent",
                                            borderRadius: hover ? "6px" : "6px",
                                          }}
                                          onClick={() =>
                                            handleTableRow("delete", item)
                                          }
                                        >
                                          <CustomDelete
                                            color={
                                              hover ? "#F96056" : "#898E99"
                                            }
                                          />
                                        </div>
                                      )}
                                    </div>
                                    <Modal
                                      open={isModelOpen[item?.id]}
                                      footer={null}
                                      className={"asp-modal-popup-small"}
                                      closable={false}
                                      centered={true}
                                      onCancel={handleCancel}
                                    >
                                      <AlertModal
                                        handleCancel={handleCancel}
                                        handleYes={() => {
                                          handleDelete(item);
                                        }}
                                        handleNo={handleNo}
                                        content={
                                          <p className="">
                                            Are you sure you want to delete this
                                            <b className="pl-5">
                                              "{item?.name}"
                                            </b>
                                            ?
                                          </p>
                                        }
                                        yesName="No, Keep it"
                                        noName="Yes, delete"
                                        loading={value1}
                                      />
                                    </Modal>
                                  </div>
                                </div>
                                <div className="messageList-left-column flex-row align-center pl-5">
                                  {item?.status === 0 && (
                                    <>
                                      <span>Created @</span>
                                      <img
                                        src={ICONS?.campaignCalendar}
                                        alt={"campaignDate"}
                                        onClick={() =>
                                          handleTableRow("templateEdit", item)
                                        }
                                      />
                                      <span>
                                        {dayjs(item?.created_at).format(
                                          "MMM DD, hh:mm a"
                                        )}
                                      </span>
                                    </>
                                  )}
                                  {item?.status === 10 && (
                                    <>
                                      <span>Starts @</span>
                                      <img
                                        src={ICONS?.campaignCalendar}
                                        alt={"campaignDate"}
                                        onClick={() =>
                                          handleTableRow("templateEdit", item)
                                        }
                                      />
                                      {item?.send_setting === "send_later" && (
                                        <span>
                                          {dayjs(item?.send_later_dt).format(
                                            "MMM DD, hh:mm a"
                                          )}
                                        </span>
                                      )}
                                      {item?.send_setting === "send_now" && (
                                        <span>
                                          {dayjs(item?.send_later_dt).format(
                                            "MMM DD"
                                          )}
                                        </span>
                                      )}
                                    </>
                                  )}
                                  {(item?.status === 11 ||
                                    item?.status === 12) && (
                                    <>
                                      {item?.status === 11 && (
                                        <span>Ends @</span>
                                      )}
                                      {item?.status === 12 && (
                                        <span>Completed @</span>
                                      )}

                                      <img
                                        src={ICONS?.campaignCalendar}
                                        alt={"campaignDate"}
                                        onClick={() =>
                                          handleTableRow("templateEdit", item)
                                        }
                                      />
                                      {item?.send_setting === "send_later" && (
                                        <span>
                                          {dayjs(item?.completed_at).format(
                                            "MMM DD, hh:mm a"
                                          )}
                                        </span>
                                      )}
                                      {item?.send_setting === "send_now" && (
                                        <span>
                                          {dayjs(item?.completed_at).format(
                                            "MMM DD"
                                          )}
                                        </span>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </td>

                            {tableColumn1[0]?.selected && (
                              <td
                                style={tdStyle}
                                className=" "
                                onClick={(event) => handleTableRow(event, item)}
                              >
                                <div
                                  className=""
                                  style={{
                                    paddingLeft: "90px",
                                    // paddingRight: "20px",
                                  }}
                                >
                                  <Status status={item?.status} />
                                </div>
                              </td>
                            )}
                            {tableColumn1[1]?.selected && (
                              <td
                                style={tdStyle}
                                onClick={(event) => handleTableRow(event, item)}
                              >
                                <div> {item?.contacts_count}</div>
                              </td>
                            )}
                            {tableColumn1[2]?.selected && (
                              <td
                                style={tdStyle}
                                onClick={(event) => handleTableRow(event, item)}
                              >
                                <div className="flex-row justify-center ">
                                  {item?.sent_count}
                                </div>
                              </td>
                            )}
                            {tableColumn1[3]?.selected && (
                              <td
                                style={tdStyle}
                                onClick={() => handleTableRow()}
                              >
                                <span className=" flex-row align-center justify-center">
                                  {item?.delivered_count}
                                </span>
                              </td>
                            )}
                            {tableColumn1[4]?.selected && (
                              <td
                                style={tdStyle}
                                onClick={() => handleTableRow()}
                              >
                                <span className=" flex-row align-center justify-center">
                                  {item?.opened_count}
                                </span>
                              </td>
                            )}
                            {tableColumn1[5]?.selected && (
                              <td
                                style={tdStyle}
                                onClick={() => handleTableRow()}
                              >
                                <span className=" flex-row align-center justify-center">
                                  {item?.leads_count}
                                </span>
                              </td>
                            )}
                            {tableColumn1[6]?.selected && (
                              <td
                                style={tdStyle}
                                onClick={() => handleTableRow()}
                              >
                                <span className=" flex-row align-center justify-center">
                                  {item?.bounced_count}
                                </span>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      )
                    );
                  })}
                </table>
              </div>
            ) : (
              <NoBroadcast />
            )}
          </Skeleton>
          {isLoadingNextPage && (
            <div
              className="w-full h-[10vh] flex items-center justify-center "
              style={{ marginBottom: "40px" }}
            >
              <Loader Width={30} Height={30} loaderBg="white" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BroadcastTable;
