import React from 'react';
import './checkbox.css';

// assets
import Tick from '../../../assets/customSVG/Tick';

// helpers
import { cn } from '../../../helper/cn';

export default function CheckBoxSqure({
  className = '',
  data,
  value,
  onChange,
  intermediate,
}) {
  return (
    <label
      className={cn(
        '!w-3 !h-3 custom-checkbox relative flex items-center justify-center hover:cursor-pointer',
        className
      )}
    >
      <input
        type='checkbox'
        name='checkbox'
        checked={value}
        className={cn(
          'squre absolute',
          intermediate ? '!border-[var(--primary)]' : ''
        )}
        onChange={(e) => {
          if (data) onChange(data);
          else onChange(e.target.checked);
        }}
      />
      {/* {value && (
        <div className='z-50'>
          <Tick width={8} height={8} stroke={'white'} strokeWidth='4' />
        </div>
      )} */}
      {intermediate && (
        <div className='contents-[""] w-1/2 border border-[var(--primary)] z-50'></div>
      )}
    </label>
  );
}
