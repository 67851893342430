import React, { useState } from "react";
import "../../../styles/contact.css";
import Close from "../../../../../assets/customSVG/Close";
import { Popover } from "antd";
import { ICONS } from "../../../../../assets/icons";
import Tick from "../../../../../assets/customSVG/Tick";
import { useParams } from "react-router-dom";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../../test/jest-redux-hooks";
import { crmTagDelete, crmTagsEdit } from "../../../api/Api";
import { updateToggleToast } from "../../../../../reduxToolkit/appSlice";

const Index = ({
  id = "",
  color = "#898E99",
  labelClassNames = "",
  mainClassNames = "",
  label = "",
  onClick = () => {},
  showClose = true,
  closeAction = () => {},
  closeColor = "",
  setVisible,
  handleDeleteTag,
}) => {
  const [hover, setHover] = useState(false);
  const [tagsOpenPopover, setTagsOpenPopover] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [value, setValue] = useState(label);

  const handleMouseOver = () => setHover(true);
  const handleMouseOut = () => setHover(false);
  const dispatch = useAspDispatch();
  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);

  const handleClick = (e) => {
    e.stopPropagation();
    setTagsOpenPopover(!tagsOpenPopover);
  };

  const handleEditTag = (e) => {
    e.stopPropagation();
    setEditOpen(true);
  };

  // const handleDeleteTag = (id) => {
  //   const brandId = currentBrand?.brand_id;
  //   crmTagDelete(id, brandId).then((res) => {
  //     if (res) {
  //     }
  //   });
  // };

  const handleUpdate = (id) => {
    const brandId = currentBrand?.brand_id;

    const payLoad = {
      tag_name: value,
    };
    crmTagsEdit(id, brandId, payLoad).then((createTagsResponse) => {
      if (createTagsResponse?.status === 200) {
        setEditOpen(false);
        setVisible(false);
        setTagsOpenPopover(false);
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: "Tag updated successfully",
              status: "Success",
              duration: "",
            },
          ])
        );
      } else {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content:
                createTagsResponse?.response?.data?.error ||
                "Tag update failed",
              status: "Error",
              duration: "",
            },
          ])
        );
      }
    });
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    if (newValue?.length <= 20) {
      setValue(newValue);
    }
  };

  const TagsEditDelete = () => {
    return (
      <div className="flex-column p-[15px] mb-3.5">
        <div
          className="flex-row align-center  m-5 pointer"
          onClick={(e) => handleEditTag(e)}
        >
          <img src={ICONS?.crmTagEdit} alt="" className="" />
          <span className="pl-10 text-[0.8vw] text-[var(--contentText)] font-500">
            Edit
          </span>
        </div>
        <div
          className="flex-row align-center pr-10 mt-10 tags-border pointer"
          onClick={() => handleDeleteTag(id)}
        >
          <img src={ICONS?.RedDeleteIcon} alt="" className="mt-10" />
          <span className="text-[0.8vw] text-[--fontRed] font-500 mt-10">
            Delete
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      {!editOpen ? (
        <p
          onClick={(e) => onClick(id, e)}
          key={color}
          className={`max-w-max ps-[0.5vw] pe-[0.2vw] rounded-full relative cursor-pointer ${mainClassNames}`}
          data-testid="addTagClick"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <span
            style={{ backgroundColor: color }}
            className="rounded-full opacity-30 w-full h-full absolute top-0 left-0"
          />
          <div
            style={{ color: color }}
            className="relative bg-transparent min-h-[22px] flex items-center text-[.8vw] font-[500] justify-start w-full pr-5 pl-1 pt-5 pb-5"
          >
            <span
              className={`overflow-ellipsis overflow-hidden whitespace-nowrap ${labelClassNames} ${
                !showClose || hover ? "mr-2" : ""
              }`}
              style={{ flex: 1 }}
            >
              {hover ? `${label.slice(0, 2)}...` : label}
            </span>

            <Popover
              trigger="click"
              open={tagsOpenPopover}
              placement="top"
              zIndex={1000}
              onOpenChange={() => setTagsOpenPopover(false)}
              content={<TagsEditDelete />}
              arrow={false}
              // overlayClassName="pt-10"
            >
              {hover && (
                <span
                  className="text-[.8vw] text-gray-500"
                  onClick={(e) => handleClick(e)}
                >
                  <img src={ICONS?.crmTagDots} alt="" />
                </span>
              )}
            </Popover>
            {showClose && !editOpen && (
              <div
                onClick={(e) => closeAction(e, id)}
                className="cursor-pointer pl-5"
              >
                <Close
                  strokeWidth="1.5"
                  width="20"
                  height="20"
                  circle={false}
                  stroke={closeColor || color}
                />
              </div>
            )}
          </div>
        </p>
      ) : (
        <div className="edit-tag-input w-[8vw]  px-3 pb-[2px] pt-[2px]  rounded-full flex-row align-center">
          <input
            type="text"
            className="w-100 text-[.8vw] font-[500] text-[var(--font-600)] p-[2px] pr-10"
            value={value}
            onChange={handleChange}
          />
          <div onClick={() => handleUpdate(id)} className="pointer">
            <Tick width="0.8vw" height="0.8vw" strokeWidth="3.2" />
          </div>
        </div>
      )}
    </>
  );
};

export default Index;
