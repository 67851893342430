import AspApi from './ApiConfig';

export const Logout = async () => await AspApi.post('api/v1/logout');

export const currentUserDetails = async () =>
  await AspApi.get('api/v1/current_user');

export const currentBrandWhatsappConnected = async (brandId) =>
  await AspApi.get(`/api/v1/channels/validate?brand_id=${brandId}`);

export const preSendUrl = async (params, payload) => {
  let qpm = [];
  if (payload?.brand_id) qpm.push(`brand_id=${payload?.brand_id}`);
  if (payload?.template_id) qpm.push(`template_id=${payload?.template_id}`);

  let qpmString = '';
  if (qpm?.length > 0) qpmString += '&' + qpm?.join('&');

  const res = await AspApi.get(
    `api/v1/presigned_url?extension_name=${params}${qpmString}`
  );
  return res;
};

export const preSendingUrl = async (params) =>
  await AspApi.get(`api/v1/presigned_url?extension_name=${params}`);

export const whatsappBusinessNumbers = async (brandId) => {
  const res = await AspApi.get(
    `/api/v1/svc/whatsapp/whatsapp_numbers/get_whatsapp_business_numbers?brand_id=${brandId}`
  );
  return res;
};
