import React, { useState } from "react";

// assets
import Chevron from "assets/customSVG/Chevron";

// utils
import { useLocation, useNavigate } from "react-router-dom";

// redux
import { useAspSelector } from "test/jest-redux-hooks";
import {
  carouselDiscard,
  templateDiscard,
} from "../../../modules/channels/api/Api";
import { Modal } from "antd";
import NewAlert from "../modal/alert/NewAlert";
import { ICONS } from "../../../assets/icons";

export default function SubSideBar({
  isListShow = true,
  list,
  activeNav = "",
  onSelect,
  color,
}) {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  const isSubmenuOpen = useAspSelector((state) => state.Channel.isSubmenuOpen);
  const { currentBrand } = useAspSelector((state) => state.app);
  const [discardAlertOpen, setDiscardAlertOpen] = useState(false);
  const templateDetails = useAspSelector((state) => state?.Template);
  const location = useLocation();

  const [toggle, setToggle] = useState(false);
  const handleItemClick = (item) => {
    if (item.subMenu) {
      onSelect(item);

      if (
        location.pathname ===
          "/user/channels/whatsapp/edit-template/template-details" &&
        (templateDetails?.details?.status === "PAM" ||
          templateDetails?.details?.status === "APPROVED" ||
          templateDetails?.details?.status === "REJECTED" ||
          templateDetails?.details?.status === "PRM")
      ) {
        {
          setDiscardAlertOpen(true);
        }
      } else {
        item.link && navigate(item.link);
      }

      if (selectedItem === item) {
        setSelectedItem(null);
        setToggle(true);
      } else {
        setSelectedItem(item);
        setToggle(false);
      }
    } else {
      if (
        location.pathname ===
          "/user/channels/whatsapp/edit-template/template-details" &&
        (templateDetails?.details?.status === "PAM" ||
          templateDetails?.details?.status === "APPROVED" ||
          templateDetails?.details?.status === "REJECTED" ||
          templateDetails?.details?.status === "PRM")
      ) {
        setDiscardAlertOpen(true);
      } else {
        onSelect(item);
        item.link && navigate(item.link);

        setSelectedItem(item);
        setToggle(false);
      }
    }
  };

  const discardCarousel = () => {
    const payLoad = {
      brand_id: currentBrand?.brand_id,
    };

    // carouselDiscard(templateDetails?.id, payLoad).then((res) => {
    //   if (res?.status === 200) {
    //     setDiscardAlertOpen(false);
    //     navigate("/user/channels/whatsapp/message-templates");
    //   }
    // });

    if (
      location.pathname ===
        "/user/channels/whatsapp/edit-template/template-details" &&
      templateDetails?.categoryType === "CAROUSEL"
    ) {
      const payLoad = {
        brand_id: currentBrand?.brand_id,
      };

      carouselDiscard(templateDetails?.id, payLoad).then((res) => {
        if (res?.status === 200) {
          setDiscardAlertOpen(false);
          navigate("/user/channels/whatsapp/message-templates");
        }
      });
    } else if (
      location.pathname ===
        "/user/channels/whatsapp/edit-template/template-details" &&
      templateDetails?.categoryType !== "CAROUSEL"
    ) {
      const payLoad = {
        brand_id: currentBrand?.brand_id,
      };

      templateDiscard(templateDetails?.id, payLoad).then((res) => {
        if (res?.status === 200) {
          setDiscardAlertOpen(false);
          navigate("/user/channels/whatsapp/message-templates");
        }
      });
    }
  };

  return (
    <div className="w-[15vw] h-full border-[0.08vw] border-[var(--layoutBorder)] bg-[var(--background)] px-[1.2vw] py-[0.8vw]">
      {list?.map((item) => {
        return (
          <>
            {item.type === "divider" ? (
              <div className='w-full contents-[""] border border-[var(--border-50)]'></div>
            ) : (
              <div className="flex-column justify-center" key={item?.id}>
                <div
                  className={`w-full font-normal weight-medium my-[0.5vw] tracking-wide text-[var(--text-black)] cursor-pointer flex items-center justify-between ${
                    !isListShow
                      ? " !bg-[#0ACD95] weight-medium rounded-[0.6vw] py-[0.4vw]"
                      : "py-[0.7vw]"
                  }`}
                  onClick={() => {
                    if (isListShow) setToggle(!toggle);
                    else handleItemClick(item);
                  }}
                >
                  <div
                    className={`${
                      isListShow
                        ? "!text-[#0ACD95]"
                        : "!text-[var(--white)] pl-[1vw]"
                    }`}
                    onClick={() => handleItemClick(item)}
                  >
                    {item?.name}
                  </div>
                  {isSubmenuOpen === false && item?.icon1 && isListShow && (
                    <Chevron
                      className={`transition-transform duration-300 ease-linear ${
                        toggle ? "rotate-0" : "rotate-180"
                      }`}
                      color={activeNav === item?.name ? "white" : "#0ACD95"}
                      width={"1.1vw"}
                      height={"1.1vw"}
                    />
                  )}
                </div>

                {isSubmenuOpen === false && !toggle && isListShow && (
                  <div className="flex-column gap-[0.7vw] font-normal weight-normal">
                    {item?.subMenu?.map((subItem) => (
                      <div
                        className={`w-full rounded-[0.6vw] px-[1vw] py-[0.4vw] flex-row align-center space-between cursor-pointer ${
                          activeNav === subItem?.name
                            ? "text-[var(--white)] !bg-[#0ACD95] weight-medium"
                            : "hover:bg-slate-100 text-[var(--font-400)] hover:text-[var(--contentBlack)] hover:weight-medium"
                        }`}
                        key={subItem?.id}
                        onClick={() => handleItemClick(subItem)}
                      >
                        {subItem?.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </>
        );
      })}

      <Modal
        // open={isDuplicateModal}
        footer={null}
        open={discardAlertOpen}
        className={"asp-modal-popup-small"}
        closable={false}
        centered={true}
        wrapClassName={"bg-[#00000095]"}

        // onCancel={handleCancel}
      >
        <NewAlert
          type="new"
          open={discardAlertOpen}
          icon={ICONS?.AlertIcon}
          setOpen={setDiscardAlertOpen}
          content={
            <div>
              <p>
                You have made some changes in the template. How would you like
                to proceed.
              </p>
            </div>
          }
          // title={"Restore Template?"}
          noName="Continue Editing"
          yesName="Discard"
          noButtonClassName="!font-md !weight-semibold"
          handleNo={() => {
            discardCarousel();
          }}
          handleYes={() => {
            setDiscardAlertOpen(false);
          }}
        />
      </Modal>
    </div>
  );
}
