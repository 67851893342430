import React, { useState, useEffect } from "react";

// assets
import { ICONS } from "../../../assets/icons/index";

// utils
import { useLocation, useNavigate } from "react-router-dom";
import { Popover, Tooltip } from "antd";

// API
import {
  currentBrandWhatsappConnected,
  currentUserDetails,
} from "../../../api/Api";

// hooks
import useToggle from "hooks/useToggle";

// redux
import { useAspSelector, useAspDispatch } from "../../../test/jest-redux-hooks";
import {
  updateCurrentAccount,
  updateCurrentBrand,
} from "../../../reduxToolkit/appSlice";

// components
import RadioButton from "../../form/radio/RadioButton";
import Loader from "../Loader/Index";

const HeaderDropDown = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { currentUser, currentAccount, currentBrand } = useAspSelector(
    (state) => state.app
  );
  const dispatch = useAspDispatch();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isloading, setIsloading] = useToggle(false);
  const [isSelecting, setIsSelecting] = useToggle(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [brandList, setBrandList] = useState([]);

  useEffect(() => {
    fetchCurrentUserDetails();
  }, [currentBrand]);

  const fetchCurrentUserDetails = async () => {
    setIsloading(true);
    try {
      const res = await currentUserDetails();
      if (res && res?.data) {
        const checkCurrentAccount = res?.data?.active_accounts?.filter(
          (b) => b?.account_id === currentAccount?.account_id
        );

        const checkBrandExist = res?.data?.active_accounts?.[0]?.brands?.filter(
          (b) =>
            b?.brand_id === currentBrand?.brand_id &&
            b?.is_whatsapp_connected === currentBrand?.is_whatsapp_connected
        );

        setBrandList(checkCurrentAccount?.[0]?.brands);
        setIsloading(false);

        if (currentBrand && checkBrandExist?.length > 0) {
          // dispatch(updateCurrentBrand(checkBrandExist?.[0]));
          dispatch(currentUser(res.data));
          if (!selectedBrand && brands.length > 0) {
            setSelectedBrand(brands[0]);
          }
        }

        if (!currentBrand) {
          dispatch(
            updateCurrentBrand(res?.data?.active_accounts?.[0]?.brands?.[0])
          );
          dispatch(currentUser(res.data));
          if (!selectedBrand && brands.length > 0) {
            setSelectedBrand(brands[0]);
          }
        }
      } else {
        setIsloading(false);
        console.error("Invalid response format:", res);
      }
    } catch (error) {
      setIsloading(false);
      // console.error('Error fetching current user details:', error);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleBrandSelect = (brand) => {
    setIsSelecting(true);
    setSelectedBrand(brand);
    if (brand?.brand_id) {
      currentBrandWhatsappConnected(brand?.brand_id).then((res) => {
        const wap = res?.data?.is_whatsapp_active;
        setTimeout(() => {
          dispatch(
            updateCurrentBrand({ ...brand, is_whatsapp_connected: wap })
          );
          setIsDropdownOpen(false);
          setIsSelecting(false);
        }, 3000);
      });
    }

    setTimeout(() => {
      navigate("/user/home");
    }, 3000);
  };

  const brandId = currentBrand
    ? currentBrand?.brand_id
    : selectedBrand?.brand_id;

  useEffect(() => {
    if (location.pathname === "/user/home" && brandId) {
      currentBrandWhatsappConnected(brandId).then((res) => {
        const wap = res?.data?.is_whatsapp_active;

        // dispatch(
        //   updateCurrentBrand({ ...selectedBrand, ...currentBrand, wap })
        // );
      });
    }
  }, [location, brandId]);

  const handleVisibleChange = (visible) => {
    setIsDropdownOpen(visible);
  };

  const PopupContent = () => {
    return (
      <div className="w-[15vw] h-auto bg-[var(--white)] rounded-[0.7vw] flex flex-col shadow-[0px_4px_30px_0px_rgba(45, 48, 54, 0.1)] overflow-hidden">
        {currentAccount?.account_name && (
          <div className="w-full h-16 text-white bg-[var(--primary)] rounded-t-[0.7vw] pl-[0.7vw] pr-[0.4vw] flex items-center gap-2.5">
            <div className="size-10 border-2 border-white rounded-full text-base weight-semibold flex items-center justify-center">
              {currentAccount?.account_name?.slice(0, 1)}
            </div>
            <div className="max-w-[90%] font-default weight-semibold tracking-wide truncate">
              {currentAccount?.account_name}
            </div>
          </div>
        )}
        <div className="pr-[0.1vw]">
          <div className="w-full h-full max-h-[32vh] overflow-y-scroll listScroll pl-[0.5vw] pr-[0.2vw] py-[0.7vw]">
            {brandList?.map((brand) => (
              <div className="w-full py-[0.5vw] border-t-[0.08vw] border-[var(--border-50)] first:border-t-0 first:pt-0 last:pb-0">
                <Tooltip trigger={["hover"]} title={brand?.brand_name}>
                  <div
                    key={brand?.id}
                    className="w-full flex items-center justify-between px-[0.2vw] py-[0.2vw] rounded-[0.4vw] cursor-pointer hover:bg-[var(--BG-25)]"
                    onClick={() => handleBrandSelect(brand)}
                  >
                    <div className="w-full flex items-center cursor-pointer px-[0.3vw] py-[0.2vw] text-[var(--textBlack)] capitalize">
                      <div className="size-[2.1vw] flex-center border-[0.08vw] border-[var(--border-100)] rounded-[0.37vw] text-[1vw] weight-semibold">
                        {brand?.brand_name?.slice(0, 1)}
                      </div>
                      <div className="px-[0.5vw]">
                        <h4 className="w-[7.5vw] font-normal weight-semibold truncate">
                          {brand?.brand_name}
                        </h4>
                        <div className="text-[#667085] font-xs mt-[0.1vw]">
                          <span>{brand?.brand_billing_plan_name}</span>
                        </div>
                      </div>
                    </div>
                    <div className="size-[1.5vw] flex-center px-[1vw] mt-[0.3vw]">
                      {!isSelecting &&
                        brand?.brand_name === currentBrand?.brand_name && (
                          <RadioButton selected={true} />
                        )}
                      {isSelecting &&
                        selectedBrand?.brand_name === brand?.brand_name && (
                          <div className="size-[2vw] flex items-center justify-center">
                            <Loader Width={20} Height={20} />
                          </div>
                        )}
                    </div>
                  </div>
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
        {/* <div className='h-9 flex-center font-xs weight-semibold'>
          <button
            className='w-auto flex flex-col'
            onClick={() => {
              navigate('/profile/account/overview');
              toggleDropdown();
            }}
            data-testid='Account Center'
          >
            <span>Account Center</span>
            <span className='w-full border-[0.01vw] border-[var(--font-400)]' />
          </button>
        </div> */}
      </div>
    );
  };

  return (
    <div className={`px-[1vw]`}>
      <Popover
        content={!isloading ? <PopupContent /> : <></>}
        trigger={!isloading ? ["click"] : []}
        arrow={false}
        placement="bottomLeft"
        open={isDropdownOpen}
        onOpenChange={(open) => {
          setIsDropdownOpen(open);
        }}
      >
        <div
          className={`w-fit ${
            isloading ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          onClick={() => {
            if (!isloading) toggleDropdown();
          }}
        >
          {!currentBrand && !selectedBrand ? (
            <div className="flex items-center gap-[0.5vw]">
              <p className="w-[7vw] h-[2.1vw] font-normal flex items-center">
                Select Brand
              </p>
              <img
                src={ICONS.dropDownIcon}
                alt="dropdown"
                className={`dropdown-icon ${isDropdownOpen ? "open" : ""}`}
              />
            </div>
          ) : (
            <Tooltip title={currentBrand?.brand_name}>
              <div className="flex items-center gap-[0.5vw] text-[var(--textBlack)]">
                <div
                  className={`size-[2.1vw] font-largest weight-semibold flex-center bg-[var(--layoutBorder)] rounded-[0.37vw] capitalize`}
                >
                  {currentBrand
                    ? currentBrand?.brand_name?.slice(0, 1)
                    : selectedBrand?.brand_name?.slice(0, 1)}
                </div>
                <div
                  className={`max-w-[6vw] min-w-[6vw] w-full font-normal weight-bold capitalize truncate`}
                >
                  {currentBrand
                    ? currentBrand?.brand_name
                    : selectedBrand?.brand_name}
                </div>
                <img
                  src={ICONS.dropDownIcon}
                  alt="dropdown"
                  className={`dropdown-icon ${isDropdownOpen ? "open" : ""}`}
                />
              </div>
            </Tooltip>
          )}
          {/* <img
            src={ICONS.dropDownIcon}
            alt='dropdown'
            className={`dropdown-icon ${isDropdownOpen ? 'open' : ''}`}
          /> */}
        </div>
      </Popover>
    </div>
  );
};

export default HeaderDropDown;
