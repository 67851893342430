import { images } from 'assets/images';

export const CHANNEL_LIST = [
  {
    id: 1,
    label: (
      <div className='flex items-center gap-2.5'>
        <img src={images.WhatsApp} alt={''} className='size-5' />
        <p>WhatsApp</p>
      </div>
    ),
    value: '',
  },
  {
    id: 2,
    label: (
      <div className='flex items-center gap-2.5'>
        <img src={images?.Sms} alt={''} className='size-5' />
        <p>SMS</p>
      </div>
    ),
    value: '',
  },
];

export const GOALS_LIST = [
  {
    id: 1,
    label: (
      <div className='flex items-center gap-2.5'>
        <img src={images.WhatsApp} alt={''} className='size-5' />
        <p>Engagement</p>
      </div>
    ),
    value: '',
  },
  {
    id: 2,
    label: (
      <div className='flex items-center gap-2.5'>
        <img src={images?.Sms} alt={''} className='size-5' />
        <p>Leads</p>
      </div>
    ),
    value: '',
  },
  {
    id: 3,
    label: (
      <div className='flex items-center gap-2.5'>
        <img src={images.WhatsApp} alt={''} className='size-5' />
        <p>Sales</p>
      </div>
    ),
    value: '',
  },
  {
    id: 4,
    label: (
      <div className='flex items-center gap-2.5'>
        <img src={images?.Sms} alt={''} className='size-5' />
        <p>Awareness</p>
      </div>
    ),
    value: '',
  },
  {
    id: 5,
    label: (
      <div className='flex items-center gap-2.5'>
        <img src={images.WhatsApp} alt={''} className='size-5' />
        <p>Branding</p>
      </div>
    ),
    value: '',
  },
  {
    id: 6,
    label: (
      <div className='flex items-center gap-2.5'>
        <img src={images?.Sms} alt={''} className='size-5' />
        <p>Support</p>
      </div>
    ),
    value: '',
  },
];

export const GOAL_TARGET_TYPES = [
  {
    id: 1,
    label: (
      <div className='flex items-center gap-2.5'>
        <img src={images.WhatsApp} alt={''} className='size-5' />
        <p>Number</p>
      </div>
    ),
    value: '',
  },
  {
    id: 2,
    label: (
      <div className='flex items-center gap-2.5'>
        <img src={images?.Sms} alt={''} className='size-5' />
        <p>Percentage</p>
      </div>
    ),
    value: '',
  },
  {
    id: 3,
    label: (
      <div className='flex items-center gap-2.5'>
        <img src={images?.Sms} alt={''} className='size-5' />
        <p>Currency</p>
      </div>
    ),
    value: '',
  },
];
