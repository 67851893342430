import React from 'react';

import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';

import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';

import { store } from './reduxToolkit/store';
import './styles/global.css';
import './styles/theme.css';
// import { FlowProvider } from './modules/campaigns/components/sequence1/context/FlowContext';

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        {/* <FlowProvider> */}
        <App />
        {/* </FlowProvider> */}
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
