import React, { useCallback, useEffect, useRef, useState } from "react";

// assets
import { ICONS } from "assets/icons";

// utils
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import {
  bodyTextLimit,
  checkVariablesAreValid,
  getCustomVariableCount,
  getVariablesCount,
  replaceCustomVariableCount,
} from "./index";

// helper
import { checkMultipleNewLines } from "../../../inbox/helper";

// hooks
import useToggle from "hooks/useToggle";

// components
import AddVariable from "./AddVariable";
import VariableComponent from "./VariableComponent";
import { Popover } from "antd";
import { cn } from "../../../../helper/cn";

export default function TemplateBody({
  type = "default",
  title,
  subTitle,
  body,
  category = "default",
  dispatch,
  language,
  showEmojiPicker,
  setShowEmojiPicker,
  variables,
  allow,
  textAreaClassName,
  onChange,
  onVariableChange,
  showVariables = true,
  count,
  onBlur,
}) {
  const [showToolbar, setShowToolbar] = useToggle(false);
  const [bodyError, setBodyError] = useState([]);
  const textareaRef = useRef(body ?? "");

  const textLimit = count ? count : bodyTextLimit;

  useEffect(() => {
    const check = checkMultipleNewLines(body);
    if (check?.length > 0) {
      if (!bodyError?.includes("line")) setBodyError([...bodyError, "line"]);
    } else {
      const remove = bodyError.filter((t) => t !== "line");
      setBodyError(remove);
    }
  }, [body]);

  // update body text
  const onBodyTextChange = (val) => {
    const textarea = textareaRef.current;
    const { selectionStart, selectionEnd, value } = textarea;

    // const update = replaceCustomVariableCount(value);
    const update = value;

    // if (type === "default")
    //   dispatch({
    //     type: "body",
    //     updateType: "variable",
    //     text: update,
    //     category: category,
    //   });
    // else if (onChange) onChange(update);

    onChange({ text: update });

    textarea.value = update;
    textarea.setSelectionRange(selectionStart, selectionEnd);
    textarea.focus();

    // check body text contains valid variables and text length are valid
    let error = [];
    const isVariablesAreInvalid = checkVariablesAreValid(update);
    if (getVariablesCount(update)?.length > textLimit) error.push("text");
    else if (getVariablesCount(update)?.length <= textLimit)
      error = error?.filter((b) => b !== "text");

    if (isVariablesAreInvalid) error.push("variable");
    else error = error?.filter((b) => b !== "variable");

    setBodyError(error);
  };

  // inline styles
  const mapKeyToEditorCommand = (e, key = null) => {
    if (!key) {
      if (e.key === "b" && e.metaKey) return "bold";
      if (e.key === "i" && e.metaKey) return "italic";
      if (e.key === "s" && e.metaKey) return "strikethrough";
    }
    if (key) return key;
    return null;
  };

  const updateInlineStyles = (e, key = null) => {
    const textarea = textareaRef.current;

    if (textarea) {
      const { selectionStart, selectionEnd, value } = textarea;
      const res = mapKeyToEditorCommand(e, key);

      let beforeSelection = value.slice(0, selectionStart);
      let selection = value.slice(selectionStart, selectionEnd);
      let afterSelection = value.slice(selectionEnd);

      if (res) {
        e.preventDefault();
        if (res === "bold") selection = `*${selection}*`;
        if (res === "italic") selection = `_${selection}_`;
        if (res === "strikethrough") selection = `~${selection}~`;

        const updatedStr = `${beforeSelection}${selection}${afterSelection}`;
        textarea.value = updatedStr;
        onBodyTextChange();
        textarea.setSelectionRange(selectionStart + 1, selectionEnd + 1);
        textarea.focus();
      }
    }
  };

  // add variable
  const addVariable = (item) => {
    const textarea = textareaRef.current;
    if (!textarea) return; // Check if textarea exists

    const { selectionStart, selectionEnd, value } = textarea;
    const customCount = getCustomVariableCount(value);

    const variable =
      item?.title === "custom-variable"
        ? `{{custom-variable${
            customCount?.length > 0 ? customCount?.length + 1 : 1
          }}}`
        : `{{${item.title}}}`;
    const beforeSelection = value.slice(0, selectionStart);
    const afterSelection = value.slice(selectionEnd);

    const checkBeforSpace = beforeSelection?.endsWith(" ");
    const checkAfterSpace = afterSelection?.startsWith(" ");

    // add space
    const newVariable =
      beforeSelection?.length > 0 && afterSelection?.length > 0
        ? checkBeforSpace
          ? `${variable} `
          : checkAfterSpace
          ? ` ${variable}`
          : checkBeforSpace && checkAfterSpace
          ? `${variable}`
          : ` ${variable} `
        : beforeSelection?.length > 0 && afterSelection?.length === 0
        ? checkBeforSpace
          ? variable
          : ` ${variable}`
        : beforeSelection?.length === 0 && afterSelection?.length > 0
        ? checkAfterSpace
          ? variable
          : `${variable} `
        : beforeSelection?.length === 0 &&
          afterSelection?.length === 0 &&
          `${variable}`;

    const newText = beforeSelection + newVariable + afterSelection;

    // replcae custom variable count
    // const updatedCustomvariableCount = replaceCustomVariableCount(newText);
    const updatedCustomvariableCount = newText;

    // if (type === "default")
    //   dispatch({
    //     type: "body",
    //     updateType: "variable",
    //     text: updatedCustomvariableCount,
    //     variableTitle: variable,
    //     variableValue: "",
    //     category: category,
    //   });
    // else if (onVariableChange)
    //   onVariableChange({
    //     type: "add",
    //     text: updatedCustomvariableCount,
    //     variableTitle: variable,
    //     variableValue: "",
    //   });

    onChange({
      key: variable,
      value: "",
      text: newText,
    });

    textarea.value = updatedCustomvariableCount;
    textarea.setSelectionRange(
      selectionStart + newVariable.length,
      selectionStart + newVariable.length
    );
    textarea.focus();

    // check body text contains valid variables and text length are valid
    let error = [];
    const isVariablesAreInvalid = checkVariablesAreValid(
      updatedCustomvariableCount
    );
    if (getVariablesCount(updatedCustomvariableCount)?.length > textLimit)
      error.push("text");
    else if (getVariablesCount(updatedCustomvariableCount)?.length <= textLimit)
      error = error?.filter((b) => b !== "text");

    if (isVariablesAreInvalid) error.push("variable");
    else error = error?.filter((b) => b !== "variable");

    setBodyError(error);

    setBodyError(error);
  };

  const handleEmojiClick = (emoji) => {
    const textarea = textareaRef.current;
    const { selectionStart, selectionEnd, value } = textarea;

    const beforeSelection = value.slice(0, selectionStart);
    const afterSelection = value.slice(selectionEnd);
    const newText = beforeSelection + emoji?.native + " " + afterSelection;

    textarea.value = newText;
    textarea.setSelectionRange(selectionStart + 3, selectionStart + 3);
    textarea.focus();

    // if (type === "default")
    //   dispatch({
    //     type: "body",
    //     updateType: "text",
    //     text: newText,
    //     category: category,
    //   });
    // else if (onChange) onChange(newText);

    onChange({ text: newText });
    setShowEmojiPicker(false);
  };

  return (
    <div className="">
      <div className="flex items-center justify-between">
        <div>
          <h3 className="weight-semibold text-[var(--contentText)] flex items-center gap-[0.2vw]">
            <span className="font-default">
              {title}
              <sup className="text-[var(--fontRed)]">*</sup>
            </span>
          </h3>
          <p className="font-md weight-medium text-[var(--font-400)] pt-[0.03vw] pb-[0.5vw]">
            {subTitle}
          </p>
        </div>
        <AddVariable
          type={"Body"}
          allow={allow}
          onChange={(item) => {
            addVariable(item);
          }}
          text={body}
        />
      </div>

      <div
        className={`w-full h-full border-[0.08vw] border-[var(--card-border)] mt-[1vw] rounded-[0.6vw] ${
          ["variable", "text"]?.includes(bodyError) ? "!border-red-400" : ""
        }`}
      >
        <div
          className="w-full h-full overflow-scroll font-md weight-small p-[0.5vw] pb-0 text-[var(--contentText)]"
          onDoubleClick={() => {
            if (allow) setShowToolbar(!showToolbar);
          }}
        >
          <textarea
            ref={textareaRef}
            value={body}
            placeholder={`Template message in ${language}`}
            onChange={(e) => {
              if (allow) onBodyTextChange(e.target.value);
            }}
            className={cn(
              "w-full h-[25vh] font-medium focus:outline-none resize-none",
              textAreaClassName
            )}
            onKeyDownCapture={(e) => {
              if (allow) updateInlineStyles(e);
            }}
            data-testid="body-text"
            onBlur={() => {
              if (onBlur) onBlur(textareaRef?.current?.value);
            }}
          />
        </div>
      </div>
      <div className="w-full h-[2vw] flex items-center justify-end gap-[0.5vw]">
        <InlineStyleControls onToggle={updateInlineStyles} />
        <Popover
          trigger={allow && showEmojiPicker ? ["click"] : []}
          open={showEmojiPicker}
          onOpenChange={(open) => {
            if (allow) setShowEmojiPicker(open);
          }}
          content={
            <Picker
              data={data}
              onEmojiSelect={(emoji) => {
                handleEmojiClick(emoji);
              }}
              searchPosition="none"
              previewPosition="none"
              data-testid="emoji-clicks"
            />
          }
        >
          <button
            className="size-[1.5vw] flex items-center justify-center rounded-[0.4vw] hover:bg-slate-100"
            type="button"
            onClick={() => {
              if (allow) setShowEmojiPicker(!showEmojiPicker);
            }}
            data-testid="emoji-click"
          >
            <img
              src={ICONS?.step3EmojiIcon}
              alt="emoji"
              className="size-[1vw]"
            />
          </button>
        </Popover>
        <div className="w-auto h-[1.5vw] flex items-center justify-end font-sm weight-medium text-[var(--font-400)] px-[0.3vw] pl-[0.4vw]">
          {getVariablesCount(body)?.length ?? 0}/{textLimit}
        </div>
      </div>
      {bodyError?.includes("variable") && (
        <div className="font-xs weight-medium text-red-500 bg-red-50 px-[0.6vw] py-[0.25vw] rounded-[0.4vw]">
          This template contains variable parameters with incorrect formatting.
          Variable parameters must be valid variable name with two sets of curly
          brackets (for example, {`{{first-name}}, {{last-name}}`}).
        </div>
      )}
      {bodyError?.includes("text") && (
        <div
          className={`font-xs weight-medium text-red-500 bg-red-50 px-[0.6vw] py-[0.25vw] rounded-[0.4vw] ${
            bodyError?.includes("variable") ? "mt-[0.4vw]" : "mt-0"
          }`}
        >
          Character limit exceeded. please shorten your content to {textLimit}{" "}
          characters.
        </div>
      )}
      {bodyError?.includes("line") && (
        <div
          className={`font-xs weight-medium text-red-500 bg-red-50 px-[0.6vw] py-[0.25vw] rounded-[0.4vw] ${
            bodyError?.includes("variable") ? "mt-[0.4vw]" : "mt-0"
          }`}
        >
          Multi-line space is not allowed in the body content.
        </div>
      )}
      {showVariables && variables?.length > 0 && (
        <div className="mt-[1vw] border-t border-t-text-[var(--border-50)]">
          <p className="font-md weight-bold text-[var(--contentText)] mt-10">
            Fallback text for body content
          </p>
          {variables?.map((v, i) => {
            return (
              <div key={`body-${i}`} className="py-[0.5vw]">
                <VariableComponent
                  name={v?.title}
                  value={v?.value}
                  onChange={(e) => {
                    if (allow) {
                      // if (type === "default")
                      //   dispatch({
                      //     type: "update_variable",
                      //     variableTitle: v.title,
                      //     variableValue: e.target.value,
                      //     category: category,
                      //   });
                      // else if (onVariableChange)
                      //   onVariableChange({
                      //     type: "update",
                      //     variableTitle: v.title,
                      //     variableValue: e.target.value,
                      //   });
                      onVariableChange({
                        key: v.title,
                        value: e.target.value,
                      });
                    }
                     
                  }}
                
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

var INLINE_STYLES = [
  { label: <img src={ICONS?.BoldIcon} alt="" />, style: "bold" },
  { label: <img src={ICONS?.ItalicIcon} alt="" />, style: "italic" },
  {
    label: <img src={ICONS?.StrikeThrough} alt="" />,
    style: "strikethrough",
  },
];

const InlineStyleControls = ({ onToggle }) => {
  return (
    <div className="h-[2vw] flex items-center justify-end gap-[0.5vw]">
      {INLINE_STYLES.map((type) => {
        return (
          <StyleButton
            key={type.style}
            active={false}
            label={type.label}
            onToggle={onToggle}
            style={type.style}
          />
        );
      })}
    </div>
  );
};

const StyleButton = ({ onToggle, label, active, style }) => {
  return (
    <span
      className={`size-[1.5vw] rounded-[0.37vw] cursor-pointer flex items-center justify-center hover:bg-slate-100 ${
        active ? "!bg-slate-100" : ""
      }`}
      onMouseDown={(e) => {
        onToggle(e, style);
      }}
      data-testid="bold-click"
    >
      {label}
    </span>
  );
};
