import React, { useState, useEffect, useRef } from "react";
import "./Table.css";
import "../../form/radio/radioSecond.css";

import { ICONS } from "../../../assets/icons/index";

import { useAspSelector, useAspDispatch } from "../../../test/jest-redux-hooks";
import {
  updateTabColumn,
  updateCampaignCount,
  setPageNavigate,
} from "../../../reduxToolkit/CampaignSlice";

import Status from "./Status";

import { useNavigate, useParams } from "react-router-dom";
import NoData from "../../../modules/campaign/components/campaigns/NoData";
import { Modal } from "antd";
import CustomDelete from "../../../assets/customSVG/CustomDelete";
import AlertModal from "../modal/alert/AlertModal";
import dayjs from "dayjs";
import CampaignModal from "../campaign-popup/CampaignModal";

const allMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const campaign_type_id = {
  2: "Time Specified",
  1: "Unspecified",
  3: "Perpetual",
};

function Table({
  tableRowClicked,
  tableList,
  selectedStatus,
  handleDelete,
  isFilter,
  isLoading,
  handleScroll,
  value,
  // selectedRows,
}) {
  // const [selectAllToggle, setSelectAllToggle] = useState(false);
  const [isLabelClicked, setIsLabelClicked] = useState(false);
  const [modalOpens, setModalOpens] = useState({});
  const [isModalVisible, setIsModalVisible] = useState({});
  const [hover, setHover] = useState(false);
  // const [anyRowChecked, setAnyRowChecked] = useState(false);

  // useEffect(() => {
  //   if (selectedRows?.length === tableList?.length) {
  //     setSelectAllToggle(true);
  //   } else {
  //     setSelectAllToggle(false);
  //   }
  // }, [selectedRows]);

  const handleCancel = () => {
    setModalOpens(false);
    setIsModalVisible(false);
  };

  const handleNo = () => {
    setModalOpens(false);
  };

  const dispatch = useAspDispatch();
  const navigate = useNavigate();

  const { tableColumn } = useAspSelector(
    (state) => state.Campaign
  );
  const { currentBrand } = useAspSelector((state) => state.app);

  const columnLength = tableColumn?.filter((item) => item?.selected)?.length;
  const tdStyle = {
    width: `calc(67% / 3)`,
  };

  // const onSelectChange = (value) => {
  //   const res = tableList?.map((l) => {
  //     return { ...l, selected: value };
  //   });
  //   setTableList(res);
  //   updatingCountList(res);
  // };

  // const setListRow = (e) => {
  //   setTableList((prev) => {
  //     const newState = prev?.map((prevItem) => {
  //       if (prevItem?.id == e.target.value) {
  //         return prevItem?.selected
  //           ? { ...prevItem, selected: false }
  //           : { ...prevItem, selected: true };
  //       } else {
  //         return { ...prevItem };
  //       }
  //     });
  //     updatingCountList(newState);
  //     setAnyRowChecked(newState.some((item) => item.selected));

  //     return newState;
  //   });
  // };

  // const updatingCountList = (data) => {
  //   const selectedCount = data?.filter((item) => item?.selected === true);
  //   selectedCount?.length > 0
  //     ? dispatch(updateCampaignCount(selectedCount?.length))
  //     : dispatch(updateCampaignCount(0));
  // };

  // const selectingAllList = () => {
  //   setSelectAllToggle(!selectAllToggle);
  //   if (selectAllToggle) {
  //     setAnyRowChecked(false);
  //   }
  // };

  const handleEditClick = (item) => {
    setIsModalVisible((prevState) => ({
      ...prevState,
      [item.id]: true,
    }));
  };

  const handleTableRow = (e, item) => {
    const itemId = item?.id;
    if (e === "delete") {
      setModalOpens((prevState) => ({
        ...prevState,
        [itemId]: true,
      }));
    } else if (e === "edit") {
      handleEditClick(item);
    } else if (item?.campaign_type_id === 1 || item?.campaign_type_id !== 3) {
      tableRowClicked(item);
      navigate(`/user/campaign/time_specified/${item?.id}`, {
        state: { item },
      });
      dispatch(setPageNavigate("Time Specified Campaigns"));
    } else if (item?.campaign_type_id !== 1 || item?.campaign_type_id === 3) {
      tableRowClicked(item);
      navigate(`/user/campaign/perpetual/${item?.id}`, { state: { item } });
      dispatch(setPageNavigate("Perpetual Campaigns"));
    }
  };

  // const onLabelClick = () => {
  //   setIsLabelClicked(!isLabelClicked);
  // };

  const statusExists = tableList?.some((item) => {
    if (selectedStatus.length > 0) return selectedStatus.includes(item?.status);
    return true;
  });

  return (
    <div className="tableWrapper !h-[85vh]" onScroll={handleScroll}>
      {tableList?.length > 0 && statusExists && currentBrand ? (
        <table
          style={{
            width: columnLength > 4 ? `${400 + columnLength * 200}px` : "100%",
          }}
        >
          {/* <thead>
            <tr className="tableHeaderRow">
              <th>
                {selectedRows?.length > 0 && (
                  <label
                    style={{ marginTop: "3.1px" }}
                    className="checkBoxContainer "
                  >
                    <input
                      type="checkbox"
                      name="checkbox"
                      checked={selectedRows?.length === tableList?.length}
                      onChange={(e) => {
                        if (selectedRows?.length === tableList?.length) {
                          onSelectChange(!selectAllToggle);
                          selectingAllList(e);
                        } else {
                          if (selectAllToggle) {
                            onSelectChange(false);
                            selectingAllList(e);
                          } else {
                            onSelectChange(true);
                            selectingAllList(e);
                          }
                        }
                      }}
                    />
                  </label>
                )}
                <div className="pl-10 campaignName ">Campaign name</div>
              </th>

              {tableColumn?.map((column) => (
                <React.Fragment key={column?.name}>
                  {column?.selected && <th>{column?.name}</th>}
                </React.Fragment>
              ))}
            </tr>
          </thead> */}

          {tableList?.map((item) => {
            const start = new Date(item?.start_date);
            const end = new Date(item?.end_date);

            const startDateString = `${
              allMonths[start?.getMonth()]
            } ${start?.getDate()}`;
            const endDateString = `${
              allMonths[end?.getMonth()]
            } ${end?.getDate()}`;

            return (
              ((selectedStatus?.length > 0 &&
                selectedStatus?.includes(item?.status)) ||
                selectedStatus?.length === 0) &&
              item?.campaign_name
                ?.toLowerCase()
                ?.includes(
                  isFilter?.toLowerCase() || isFilter?.toUpperCase()
                ) &&
              statusExists && (
                <React.Fragment key={item?.id}>
                  {(modalOpens[item?.id] || isModalVisible[item?.id]) && (
                    <div className="modal-overlay"></div>
                  )}

                  <tbody>
                    <tr className="listRow" key={item?.id}>
                      <td>
                        <div
                          className="rowBar"
                          style={{ backgroundColor: item?.campaign_color }}
                        />
                        <div className="flex-row firstColumnWrapper">
                          {/* <div
                            className={`campaign-radio flex-row ${
                              item?.selected
                                ? "campaignBoxRadioActive mr-10"
                                : "campaignBoxRadioButton"
                            }`}
                          >
                            <label
                              className="checkBoxContainer mt-5 ml-5"
                              onClick={onLabelClick}
                            >
                              <input
                                type="checkbox"
                                name="checkbox"
                                checked={item.selected}
                                value={item?.id}
                                onChange={(e) => setListRow(e, item?.id)}
                                data-testid="check_box"
                              />
                            </label>
                          </div> */}
                          <div className="campaignNameBox flex-column  justify-center ">
                            <div className="flex-row align-center">
                              <p
                                onClick={(event) => handleTableRow(event, item)}
                                data-testid="Campaign_nameTesting"
                              >
                                {item?.campaign_name
                                  ? item.campaign_name
                                      ?.charAt(0)
                                      ?.toUpperCase() +
                                    item?.campaign_name?.slice(1)
                                  : ""}
                              </p>

                              <div
                                className={`flex-row  ${
                                  item?.selected
                                    ? "iconWrapperActive "
                                    : "iconWrapper"
                                }`}
                              ></div>
                              <div className="messageTable-icons pointer ml-10">
                                <div
                                  onClick={() => handleTableRow("edit", item)}
                                  className="edit-icon"
                                >
                                  {(item?.status === 10 ||
                                    item?.status === 11) &&
                                    item?.campaign_type_id !== 3 && (
                                      <img src={ICONS?.edit} alt="edit" />
                                    )}
                                </div>
                              </div>
                              <div className="messageTable-icons pointer ml-5">
                                {item?.campaign_type_id !== 3 && (
                                  <div
                                    onMouseOver={() => setHover(true)}
                                    onMouseLeave={() => setHover(false)}
                                    style={{
                                      background: hover
                                        ? "#FFEEED"
                                        : "transparent",
                                      borderRadius: hover ? "4px" : "4px",
                                      border: hover ? "0px solid red" : "",
                                    }}
                                    onClick={() => {
                                      handleTableRow("delete", item);
                                    }}
                                    data-testid="unique-div"
                                  >
                                    <CustomDelete
                                      color={hover ? "#F96056" : "#898E99"}
                                    />
                                  </div>
                                )}
                                <Modal
                                  open={modalOpens[item?.id]}
                                  footer={null}
                                  className={"asp-modal-popup-small"}
                                  closable={false}
                                  centered={true}
                                  onCancel={handleCancel}
                                >
                                  <AlertModal
                                    handleCancel={handleCancel}
                                    handleYes={() => {
                                      handleDelete(item);
                                    }}
                                    handleNo={handleNo}
                                    content={
                                      <p>
                                        Are you sure you want to delete this{" "}
                                        <b>"{item?.campaign_name}"</b> ?
                                      </p>
                                    }
                                    yesName="No, Keep it"
                                    noName="Yes, delete"
                                    loading={value}
                                  />
                                </Modal>
                                <Modal
                                  open={isModalVisible[item?.id]}
                                  footer={null}
                                  className={"campaign-modal"}
                                  closable={false}
                                  centered={true}
                                  width="40%"
                                  destroyOnClose={true}
                                  onCancel={handleCancel}
                                >
                                  <CampaignModal
                                    type="edit"
                                    handleCancel={handleCancel}
                                    setIsModalVisible={() =>
                                      setIsModalVisible(true)
                                    }
                                    item={item}
                                  />
                                </Modal>
                              </div>
                            </div>

                            <div
                              className="flex-row campaignDetails align-center"
                              onClick={(event) => handleTableRow(event, item)}
                            >
                              Created @
                              <img
                                src={ICONS?.campaignCalendar}
                                alt={"campaignDate"}
                                className="campaignBoxImage"
                              />
                              <span className="weight-semibold">
                                {dayjs(item?.created_at).format(
                                  "MMM DD hh:mm a"
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                      {tableColumn[0]?.selected && (
                        <td
                          style={tdStyle}
                          onClick={(event) => handleTableRow(event, item)}
                        >
                          <div className="flex-row justify-center ">
                            <span className="status-complete">
                              <Status status={item?.status} />
                            </span>
                          </div>
                        </td>
                      )}

                      {tableColumn[1]?.selected && (
                        <td
                          style={tdStyle}
                          onClick={(event) => handleTableRow(event, item)}
                        >
                          <div className="status-complete">
                            {campaign_type_id[item?.campaign_type_id]}
                          </div>
                        </td>
                      )}

                      {tableColumn[2]?.selected && (
                        <td
                          style={tdStyle}
                          onClick={(event) => handleTableRow(event, item)}
                        >
                          <div className="duration flex-row align-center justify-center">
                            <img
                              src={ICONS?.navCalendar}
                              alt="img"
                              className="durationCalender"
                            />
                            <span className="status-complete">
                              {startDateString || "---"} - {endDateString}
                            </span>
                          </div>
                          <div className="duration-open-calendar"></div>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </React.Fragment>
              )
            );
          })}
        </table>
      ) : (
        <NoData isLoading={isLoading} />
      )}
    </div>
  );
}

export default Table;
