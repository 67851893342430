import { ICONS } from 'assets/icons';

export const CAMPAIGN_STATUS = {
  0: {
    icon: ICONS?.CampaignDraftIcon,
    text: 'Draft',
    color: '#8B5CF6',
  },
  10: {
    icon: ICONS?.BroadCastActive,
    text: 'SCHEDULED',
    color: '#0ACD95',
  },
  11: {
    icon: ICONS?.CampaignRunningIcon,
    text: 'Running',
    color: '#0ACD95',
  },
  12: {
    icon: ICONS?.CampaignCompleteIcon,
    text: 'Completed',
    color: '#4C90F5',
  },
};
